import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AssessmentStatus } from '../../../interfaces';
import AssessmentStatusBadge from '../../../shared/components/AssessmentStatusBadge/AssessmentStatusBadge';
import Button from '../../../shared/components/Button/Button';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';

interface Props {
  assessmentId: number;
  title: string;
  status: AssessmentStatus;
  onClick: (id: number) => void;
}

const Assessment = ({ assessmentId, title, status, onClick }: Props) => {
  const { t } = useTranslation();

  const clickHandler = (): void => onClick(assessmentId);

  return (
    <div
      className={clsx(
        'mb-2 flex h-[76px] w-full items-center justify-between rounded-md p-4',
        { 'bg-assessment-card-not-started': status === 'not-started' },
        { 'bg-assessment-card-in-progress': status === 'in-progress' },
        { 'bg-assessment-card-completed': status === 'completed' }
      )}
    >
      {/* Text */}
      <div className="flex flex-col items-start justify-center">
        <HeadingText
          variant="primary-small"
          className={clsx('mb-1 font-semibold', {
            'text-[#111111]': status === 'not-started',
            'text-[#ffffff]': status === 'completed',
          })}
        >
          {title}
        </HeadingText>
        <HeadingText
          variant="secondary"
          className={clsx({
            'text-[#111111]': status === 'not-started',
            'text-[#b3d7de]': status === 'completed',
          })}
        >
          <AssessmentStatusBadge status={status} />
        </HeadingText>
      </div>

      {/* Actions */}
      <div>
        {/* not-started button */}
        {status === 'not-started' && (
          <Button
            className="default min-w-28"
            onClick={clickHandler}
          >
            {t('360.assessments.task-action.start')}
          </Button>
        )}

        {/* in-progress button */}
        {status === 'in-progress' && (
          <Button
            className="secondary min-w-28"
            onClick={clickHandler}
          >
            {t('360.assessments.task-action.complete')}
          </Button>
        )}

        {/* completed button */}
        {status === 'completed' && (
          <Button
            className="secondary min-w-28"
            onClick={clickHandler}
          >
            {t('360.assessments.task-action.view')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Assessment;
