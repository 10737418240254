import { Outlet } from 'react-router-dom';
import { useSocketInstance } from '../../App';

const ApplicationAlias = () => {
  const { socket, connected } = useSocketInstance();

  return <Outlet context={{ socket, connected }} />;
};

export default ApplicationAlias;
