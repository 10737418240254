import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import LanguageSelector from '../../../shared/components/LanguageSelector/LanguageSelector';
import { getCompany } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';

const AssessmentsHeader = () => {

  const { t } = useTranslation();

  const company = useAppSelector(getCompany);

  return (
    <div className={clsx(
      'w-full py-2 px-4 flex flex-row items-center justify-between border-b border-b-[#D0D5DD] shadow',
      'lg:py-2 lg:px-6'
    )}>
      <div
        className="flex flex-row">
        <div
          className="mr-4">
          <img src="/unicredit-logo-small.svg" alt="Unicredit official logo - small version" />
        </div>
        <div>
          <HeadingText className="font-bold">{t('360.assessments.header.title')}</HeadingText>
          <HeadingText variant="secondary">{t('360.assessments.header.subtitle', { companyName: company.name })}</HeadingText>
        </div>
      </div>
      <LanguageSelector />
    </div>
  );
};

export default AssessmentsHeader;
