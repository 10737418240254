import clsx from 'clsx';
import { HTMLAttributes } from 'react';

type Variants = 'primary' | 'primary-small' | 'secondary' | 'secondary-small' | 'heading';

interface TextProps extends HTMLAttributes<HTMLDivElement> {
  variant?: Variants;
}

const HeadingText = ({ variant = 'primary', children, ...props }: TextProps) => {
  const variants: Record<Variants, string> = {
    primary: 'text-xl text-[#101828]',
    'primary-small': 'text-md text-[#101828]',
    secondary: 'text-sm text-[#475467]',
    'secondary-small': 'text-xs text-[#475467]',
    heading: 'text-3xl text-[#111111]'
  };

  return (
    <div
      {...props}
      className={clsx(variants[variant], props.className)}
    >
      {children}
    </div>
  );
};

export default HeadingText;
