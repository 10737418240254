import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface GuestUiErrors {
  name?: string;
  surname?: string;
  email?: string;
  stakeholders_limit?: string;
}

export interface GuestUIState {
  loading: boolean;
  errors: GuestUiErrors;
}

export interface IntroUIState {
  loading: boolean;
}

export interface SummaryUIState {
  loading: boolean;
}

export interface BriefingUIState {
  loading: boolean;
}

export interface VideoInterviewUIState {
  loading: boolean;
}

export interface QuestionnaireUIState {
  loading: boolean;
}

export interface UIState {
  guest: GuestUIState;
  intro: IntroUIState;
  summary: SummaryUIState;
  briefing: BriefingUIState;
  video_interview: VideoInterviewUIState;
  questionnaire: QuestionnaireUIState;
  prev_loading: boolean;
  next_loading: boolean;
}

const initialState: UIState = {
  guest: {
    loading: false,
    errors: {} as GuestUiErrors,
  },
  intro: {
    loading: false,
  },
  summary: {
    loading: false,
  },
  briefing: {
    loading: false,
  },
  video_interview: {
    loading: false,
  },
  questionnaire: {
    loading: false,
  },
  prev_loading: false,
  next_loading: false,
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    GUEST_ERROR: (state, action: PayloadAction<{ name: string; error: string }>) => ({
      ...state,
      guest: {
        ...state.guest,
        errors: {
          ...state.guest.errors,
          [action.payload.name]: action.payload.error,
        },
      },
    }),
    GUEST_ERROR_RESET: (state, action: PayloadAction<string>) => {
      const errors: GuestUiErrors = { ...state.guest.errors };
      delete errors[action.payload as keyof GuestUiErrors];
      return {
        ...state,
        guest: {
          ...state.guest,
          errors,
        },
      };
    },
    GUEST_ERROR_RESET_ALL: (state) => ({
      ...state,
      guest: {
        ...state.guest,
        errors: {} as GuestUiErrors,
      },
    }),
    GUEST_SUBMIT: (state) => ({
      ...state,
      guest: {
        ...state.guest,
        loading: true,
      },
    }),
    INTRO_START: (state) => ({
      ...state,
      intro: {
        loading: false,
      },
    }),
    INTRO_SUBMIT: (state) => ({
      ...state,
      intro: {
        loading: true,
      },
    }),
    SUMMARY_START: (state) => ({
      ...state,
      summary: {
        loading: false,
      },
    }),
    SUMMARY_SUBMIT: (state) => ({
      ...state,
      summary: {
        loading: true,
      },
    }),
    BRIEFING_START: (state) => ({
      ...state,
      briefing: {
        loading: false,
      },
    }),
    BRIEFING_SUBMIT: (state) => ({
      ...state,
      briefing: {
        loading: true,
      },
    }),
    VIDEOINTERVIEW_SUBMIT: (state) => ({
      ...state,
      video_interview: {
        ...state.video_interview,
        loading: false,
      },
    }),
    VIDEOINTERVIEW_SUBMITTED: (state) => ({
      ...state,
      video_interview: {
        ...state.video_interview,
        loading: true,
      },
    }),
    QUESTIONNAIRE_SUBMIT: (state) => ({
      ...state,
      questionnaire: {
        ...state.questionnaire,
        loading: false,
      },
    }),
    QUESTIONNAIRE_SUBMITTED: (state) => ({
      ...state,
      questionnaire: {
        ...state.questionnaire,
        loading: true,
      },
    }),
    QUESTIONNAIRE_PREV_CLICKED: (state) => ({
      ...state,
      prev_loading: !state.prev_loading,
    }),
    QUESTIONNAIRE_NEXT_CLICKED: (state) => ({
      ...state,
      next_loading: !state.next_loading,
    }),
    QUESTIONNAIRE_DISABLE_PREV_LOADING: (state) => ({
      ...state,
      prev_loading: false,
    }),
    QUESTIONNAIRE_DISABLE_NEXT_LOADING: (state) => ({
      ...state,
      next_loading: false,
    }),
  },
});

export default slice.reducer;

export const actions = { ...slice.actions };
