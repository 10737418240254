import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const CircleCaretRightIcon = ({ color = '#525252', ...props }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M1.6 8a6.4 6.4 0 1 0 12.8 0A6.4 6.4 0 0 0 1.6 8Zm9.2 0c0 .168-.07.325-.192.44l-2.8 2.6A.6.6 0 0 1 6.8 10.6V5.4a.6.6 0 0 1 1.008-.44l2.8 2.6A.598.598 0 0 1 10.8 8Z"
      />
    </svg>
  );

};

export default CircleCaretRightIcon;
