import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const MemoPadIcon = ({ color = '#525252', ...props }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M12.714 4.667V13c0 .23-.193.417-.428.417H3.714A.424.424 0 0 1 3.286 13V4.667h9.428Zm-9-3.333C2.77 1.333 2 2.08 2 3v10c0 .92.769 1.667 1.714 1.667h8.572C13.23 14.667 14 13.919 14 13V3c0-.92-.769-1.667-1.714-1.667H3.714Zm.857 5.208c0 .346.287.625.643.625h5.572a.633.633 0 0 0 .643-.625.633.633 0 0 0-.643-.625H5.214a.633.633 0 0 0-.643.625Zm0 2.5c0 .346.287.625.643.625h5.572a.633.633 0 0 0 .643-.625.633.633 0 0 0-.643-.625H5.214a.633.633 0 0 0-.643.625Zm0 2.5c0 .346.287.625.643.625h3a.633.633 0 0 0 .643-.625.633.633 0 0 0-.643-.625h-3a.633.633 0 0 0-.643.625Z"
      />
    </svg>
  );

};

export default MemoPadIcon;
