import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSocketInstance } from '../../App';

const { VITE_APP_ASSESSMENT_ALLOWED } = import.meta.env;

const JobPostAlias = () => {
  const { socket, connected } = useSocketInstance();

  const navigator = useNavigate();

  const assessment_allowed = VITE_APP_ASSESSMENT_ALLOWED && VITE_APP_ASSESSMENT_ALLOWED === 'true';

  useEffect(() => {
    if (typeof assessment_allowed === 'boolean' && !assessment_allowed) {
      navigator('/assessments-closed');
    }
  }, [assessment_allowed]);

  return <Outlet context={{ socket, connected }} />;
};

export default JobPostAlias;
