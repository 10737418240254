import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<SVGElement> {
  color?: string;
}

const CheckIcon = ({ color = '#FFFFFF', ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill={color}
        d="M13.486 4.184a.622.622 0 0 1 0 .882L6.61 11.944a.622.622 0 0 1-.883 0L2.184 8.402a.625.625 0 0 1 .883-.883l3.098 3.1L12.6 4.183a.622.622 0 0 1 .883 0h.003Z"
      />
    </svg>
  );
};

export default CheckIcon;
