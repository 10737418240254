import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { DisconnectDescription } from 'socket.io-client/build/esm/socket';
import { Application } from '../../interfaces';
import {
  ALLOWED_DOMAINS_RETRIEVED,
  LANGUAGES_RETRIEVED,
  MANAGER_APPROVAL_SUBMITTED,
  QUESTIONNAIRE_NEXT,
  QUESTIONNAIRE_PREV,
  RETRIEVE_EXAM,
  SET_QUERY_ERROR,
  TASK_RETRIEVED,
} from '../../store/exam/reducer';
import { useAppDispatch } from '../../store/store';
import { actions } from '../../store/ui/reducer';
import {
  ExamFoundPayload,
  GoToPayload,
  LanguagesRetrievedPayload,
  ManagerApprovalSubmitPayload,
  QuestionnaireNextAuthorizedPayload,
  QuestionnairePrevAuthorizedPayload,
  TaskRetrievedPayload,
} from './socket.interfaces';

const API_BASE_URL = import.meta.env.VITE_APP_API_BASE_URL;

const socket: Socket = io(`${API_BASE_URL}/360feedback`, {
  // withCredentials: true // TODO:Manage Auth
  transports: ['polling', 'websocket'], // ['polling', 'websocket']
  timeout: 30000, // default 20000
});

interface HookProps {
  socket: Socket;
  connected: boolean;
}

const useSocket = (): HookProps => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const referral_hash: string | null = searchParams.get('i');

  const [disconnected, setDisconnected] = useState<boolean>(false);

  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  const navigator = useNavigate();

  useEffect(() => {
    const connectHandler = () => {
      console.log('Connected:', socket.connected);
      console.log('Socket ID:', socket.id);
      socket.emit('available-languages');
      socket.emit('allowed-domains');

      if (job_post_alias && application_alias) {
        socket.emit('whoami', { job_post_alias, application_alias, silent: disconnected });
        setDisconnected(false);
      } else if (job_post_alias && !application_alias) {
        socket.emit('iamguest', { job_post_alias, referral_hash });
      }
    };

    const disconnectHandler = (
      reason: Socket.DisconnectReason,
      details: DisconnectDescription | undefined
    ) => {
      console.log('Reason:', reason);
      console.log('Details:', details);
      setDisconnected(true);
    };

    const languagesRetrievedHandler = ({ languages }: LanguagesRetrievedPayload): void => {
      dispatch(LANGUAGES_RETRIEVED({ languages }));
    };

    const allowedDomainsRetrievedHandler = ({
      allowed_domains,
    }: {
      allowed_domains: string[];
    }): void => {
      dispatch(ALLOWED_DOMAINS_RETRIEVED({ allowed_domains }));
    };

    const guestAuthenticatedHandler = ({ application }: { application: Application }): void => {
      socket.emit('whoami', {
        job_post_alias,
        application_alias: application.alias,
        silent: disconnected,
      });
    };

    const requestedTaskRetrievedHandler = ({ task, feedbacks }: TaskRetrievedPayload): void => {
      dispatch(TASK_RETRIEVED({ task, feedbacks }));
    };

    const questionnairePrevAuthorizedHandler = ({
      cluster,
      questions,
    }: QuestionnairePrevAuthorizedPayload): void => {
      dispatch(actions.QUESTIONNAIRE_PREV_CLICKED());
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        dispatch(QUESTIONNAIRE_PREV({ cluster, questions }));
        dispatch(actions.QUESTIONNAIRE_PREV_CLICKED());
      }, 500);
    };

    const questionnaireNextAuthorizedHandler = ({
      cluster,
      questions,
    }: QuestionnaireNextAuthorizedPayload): void => {
      dispatch(actions.QUESTIONNAIRE_NEXT_CLICKED());
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        dispatch(QUESTIONNAIRE_NEXT({ cluster, questions }));
        dispatch(actions.QUESTIONNAIRE_NEXT_CLICKED());
      }, 500);
    };

    const examFoundHandler = (payload: ExamFoundPayload) => {
      dispatch(RETRIEVE_EXAM(payload));
      setDisconnected(false);
    };

    const feedbackCompletedHandler = (): void => {
      dispatch(actions.QUESTIONNAIRE_DISABLE_PREV_LOADING());
      dispatch(actions.QUESTIONNAIRE_DISABLE_NEXT_LOADING());
    };

    const goToHandler = ({ path, data }: GoToPayload) => {
      if (data) {
        if (data.briefing) {
          navigator(`${path}/briefing`);
        } else if (data.type) {
          navigator(`${path}/${data.type}`);
        } else {
          navigator(path);
        }
      } else {
        navigator(path);
      }
    };

    const guestEmailErrorHandler = (): void => {
      dispatch(
        actions.GUEST_ERROR({ name: 'email', error: t('useSocket.guest.error.duplicateEmail') })
      );
    };

    const stakeHoldersLimitReachedHandler = (): void => {
      dispatch(
        actions.GUEST_ERROR({
          name: 'stakeholders_limit',
          error: t('useSocket.guest.error.stakeholdersLimitReached'),
        })
      );
    };

    const managerApprovalSubmittedHandler = ({
      voted,
      manager,
      alreadyVoted,
    }: ManagerApprovalSubmitPayload): void => {
      dispatch(MANAGER_APPROVAL_SUBMITTED({ voted, manager, alreadyVoted }));
    };

    const queryErrorHandler = () => {
      dispatch(SET_QUERY_ERROR(true));
    };

    socket.on('connect', connectHandler);
    socket.on('disconnect', disconnectHandler);
    socket.on('languages-retrieved', languagesRetrievedHandler);
    socket.on('domains-retrieved', allowedDomainsRetrievedHandler);
    socket.on('guest-authenticated', guestAuthenticatedHandler);
    socket.on('requested-task-retrieved', requestedTaskRetrievedHandler);
    socket.on('questionnaire-prev-authorized', questionnairePrevAuthorizedHandler);
    socket.on('questionnaire-next-authorized', questionnaireNextAuthorizedHandler);
    socket.on('exam-found', examFoundHandler);
    socket.on('feedback-completed', feedbackCompletedHandler);
    socket.on('goto', goToHandler);
    socket.on('email-already-exists', guestEmailErrorHandler);
    socket.on('stakeholders-limit-reached', stakeHoldersLimitReachedHandler);
    socket.on('manager-approval-submitted', managerApprovalSubmittedHandler);
    socket.on('query-error', queryErrorHandler);

    return () => {
      socket.off('connect', connectHandler);
      socket.off('disconnect', disconnectHandler);
      socket.off('languages-retrieved', languagesRetrievedHandler);
      socket.off('domains-retrieved', allowedDomainsRetrievedHandler);
      socket.off('guest-authenticated', guestAuthenticatedHandler);
      socket.off('requested-task-retrieved', requestedTaskRetrievedHandler);
      socket.off('questionnaire-prev-authorized', questionnairePrevAuthorizedHandler);
      socket.off('questionnaire-next-authorized', questionnaireNextAuthorizedHandler);
      socket.off('exam-found', examFoundHandler);
      socket.off('feedback-completed', feedbackCompletedHandler);
      socket.off('goto', goToHandler);
      socket.off('email-already-exists', guestEmailErrorHandler);
      socket.off('stakeholders-limit-reached', stakeHoldersLimitReachedHandler);
      socket.off('manager-approval-submitted', managerApprovalSubmittedHandler);
      socket.off('query-error', queryErrorHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnected]);

  return { socket, connected: !disconnected };
};

export default useSocket;
