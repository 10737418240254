import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import HeadingText from '../../HeadingText/HeadingText';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

const SaveDraftDialog = ({ onConfirm, onClose }: Props) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <div className="flex w-[28rem] max-w-full flex-col items-center gap-2">
      <HeadingText
        variant="heading"
        className="text-center"
      >
        {t('360.dialog.saveToDraft.title')}
      </HeadingText>
      <p className="mb-10 max-w-[80%] text-center text-[#525252]">
        {t('360.dialog.saveToDraft.subtitle')}
      </p>
      <Button
        className="default lg:w-full"
        onClick={onConfirm}
      >
        {t('360.dialog.saveToDraft.button.confirm')}
      </Button>
      <Button
        className="secondary lg:w-full"
        onClick={onClose}
      >
        {t('360.dialog.saveToDraft.button.cancel')}
      </Button>
    </div>
  );
};

export default SaveDraftDialog;
