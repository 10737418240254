import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Q360Task, Q360TaskGroup } from '../../../interfaces';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import Spinner from '../../../shared/components/Icons/Spinner';
import ShareLink from '../../../shared/components/ShareLink/ShareLink';
import { formatDate } from '../../../shared/utils/date';
import i18n from '../../../shared/utils/i18n';
import { get360SelfTask, get360TaskList, getCanShareLink } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import Assessment from './Assessment';
import AssessmentsGroup from './AssessmentsGroup';

const { VITE_APP_360FEEDBACK_BASE_URL, VITE_APP_ASSESSMENT_CLOSING_DATE } = import.meta.env;

interface Props {
  loading: boolean;
  onClick: (id: number) => void;
}

const AssessmentsList = ({ loading, onClick }: Props) => {

  const { t } = useTranslation();

  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  const invitation_link = `${VITE_APP_360FEEDBACK_BASE_URL}/${job_post_alias}?i=${application_alias}`;
  const can_share = useAppSelector(getCanShareLink);
  const self_task = useAppSelector(get360SelfTask);
  const task_list = useAppSelector(get360TaskList);

  const copyLinkHandler = async (): Promise<void> => {
    await window.navigator.clipboard.writeText(
      `${t('360.shareLink.copy', { closing_date: formatDate(VITE_APP_ASSESSMENT_CLOSING_DATE, false, i18n.resolvedLanguage) })}\n\n${invitation_link}`
    );
  };

  return (
    <div
      className={clsx(
        'relative p-6 w-full'
      )}>

      {/* Title */}
      <div
        className="mb-6">
        <HeadingText
          variant='heading'
          className="font-bold">{t('360.assessments.heading')}</HeadingText>
      </div>

      {/* Self assessment */}
      {
        self_task && Object.keys(self_task).length > 0 && (
          <Assessment
            assessmentId={self_task.id}
            title={t('360.assessments.selfAssessment.title')}
            status={self_task.status}
            onClick={onClick} />
        )
      }

      {/* Assessment groups */}
      {
        (Object.keys(task_list) as Q360TaskGroup[])
          .filter((taskGroup: Q360TaskGroup) => task_list[taskGroup] && task_list[taskGroup].length > 0)
          .map((taskGroup: Q360TaskGroup) => (
            <AssessmentsGroup
              key={`assessment-group-${taskGroup}`}
              group={task_list[taskGroup]}
              title={taskGroup}
              total={task_list[taskGroup].length}
              completed={task_list[taskGroup].reduce((acc: number, v: Q360Task) => v.status === 'completed' ? acc + 1 : acc, 0)}
              onClick={onClick} />
          ))
      }

      {
        loading && (
          <div
            className="z-50 fixed top-0 left-0 w-full h-full bg-white opacity-80 flex items-center justify-center">
            <Spinner size={64} />
          </div>
        )
      }

      {
        can_share && (
          <div
            className="w-full flex justify-center">
            <ShareLink onCopyLink={copyLinkHandler} />
          </div>
        )
      }

    </div>
  );

};

export default AssessmentsList;
