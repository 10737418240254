import clsx from 'clsx';
import LanguageSelector from '../../shared/components/LanguageSelector/LanguageSelector';

const OTPHeader = () => {

  return (
    <div className={clsx(
      'w-full py-4 px-4 flex flex-row items-center justify-between border-b border-b-[#D0D5DD] shadow',
      'lg:py-2 lg:px-6 lg:shadow-none lg:border-none'
    )}>
      <div
        className="flex flex-row">
        <div
          className="mr-4 max-w-[126px]">
          <img src="https://uc360-status.algointerview.it/unicredit_logo.png" alt="Unicredit official logo - small version" />
        </div>
      </div>
      <LanguageSelector />
    </div>
  );
};

export default OTPHeader;
