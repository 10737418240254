import clsx from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../interfaces';
import { getAvailableLanguages } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import i18n from '../../utils/i18n';
import EnglishFlagIcon from '../Icons/EnglishFlagIcon';
import GermanFlagIcon from '../Icons/GermanFlagIcon';
import ItalianFlagIcon from '../Icons/ItalianFlagIcon';

const LanguageSelector = () => {

  const { t } = useTranslation();

  const { resolvedLanguage } = i18n;

  const languages = useAppSelector(getAvailableLanguages);

  const [currentLanguage, setCurrentLanguage] = useState<Language | undefined>(undefined);

  const changeHandler = (e: ChangeEvent<HTMLSelectElement>): void => {
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    if (resolvedLanguage) {
      const current_language: Language = languages.find(
        (language: Pick<Language, "id" | "label" | "iso_code">) => language.iso_code === i18n.resolvedLanguage
      ) as Language;
      setCurrentLanguage(current_language);
    }
  }, [languages, resolvedLanguage]);

  return (
    <div
      className="flex items-center justify-start relative">
      {
        currentLanguage && (
          <>
            <select
              className="absolute top-0 left-0 w-full h-full opacity-0"
              name="language"
              id="language"
              defaultValue={currentLanguage.iso_code}
              onChange={changeHandler}>
              {
                languages.map((language: Pick<Language, "id" | "label" | "iso_code">) => (
                  <option
                    key={`language-${language.iso_code}`}
                    value={language.iso_code}>{t(`360.languages.${language.iso_code}`)}</option>
                ))
              }
            </select>
            <div
              className={clsx(
                'mr-2'
              )}>
              {currentLanguage.iso_code === 'it' && <ItalianFlagIcon />}
              {currentLanguage.iso_code === 'en' && <EnglishFlagIcon />}
              {currentLanguage.iso_code === 'de' && <GermanFlagIcon />}
            </div>
            <div
              className={clsx(
                'hidden',
                'lg:flex mr-2'
              )}>
              {t(`360.languages.${currentLanguage.iso_code}`)}
            </div>
            <div
              className={clsx(
                'mr-2'
              )}>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3831 6.75429L8.62514 12.2176C8.45089 12.4161 8.2236 12.5001 8.0001 12.5001C7.7766 12.5001 7.55007 12.4165 7.37506 12.2496L1.61708 6.75429C1.25228 6.40305 1.23713 5.82274 1.58147 5.45623C1.92733 5.08591 2.50502 5.07445 2.86717 5.42187L8.0001 10.3201L13.133 5.41806C13.4953 5.07086 14.0706 5.084 14.4187 5.45385C14.7619 5.82274 14.7468 6.40305 14.3831 6.75429Z" fill="#666666" />
              </svg>
            </div>
          </>
        )
      }
    </div>
  );

};

export default LanguageSelector;
