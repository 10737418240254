import clsx from 'clsx';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSocketInstance } from '../../App';
import Spinner from '../../shared/components/Icons/Spinner';
import LanguageSelector from '../../shared/components/LanguageSelector/LanguageSelector';
import { DISABLE_LOADING } from '../../store/exam/reducer';
import { getManagerAlreadyVoted, getManagerApprovalLoading, getManagerName, getManagerSurname, getVotedName, getVotedSurname } from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import ApprovalStatus from './ApprovalStatus';

const Approval = () => {

  const { socket } = useSocketInstance();

  const dispatch = useAppDispatch();

  const navigator = useNavigate();

  const [params] = useSearchParams();

  const { voted_alias } = useParams<{ voted_alias: string; }>();

  const status = params.get('status') as 'approve' | 'reject' | null;

  const loading = useAppSelector(getManagerApprovalLoading);
  const applicationName = useAppSelector(getVotedName);
  const applicationSurname = useAppSelector(getVotedSurname);
  const managerName = useAppSelector(getManagerName);
  const managerSurname = useAppSelector(getManagerSurname);
  const alreadyDone = useAppSelector(getManagerAlreadyVoted);

  useEffect(() => {
    if (!status || !voted_alias || !['approve', 'reject'].includes(status)) {
      navigator(`/not-found`);
    }
  }, [status, voted_alias]);

  useEffect(() => {
    dispatch(DISABLE_LOADING());
    socket.emit('manager-approval-submit', { voted_alias, status });
  }, []);

  return (
    <div className="m-[auto] flex h-full w-full items-center justify-center">
      <div
        className={clsx(
          'h-[580px] flex w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff] px-4',
          'lg:shadow-cust-1'
        )}
      >
        <div className={clsx(
          'w-full py-4 px-4 flex flex-row items-center justify-end',
          'lg:p-4'
        )}>
          <LanguageSelector />
        </div>

        <div
          className="flex flex-col w-full h-full justify-center items-center flex-1">
          {
            loading && (
              <div
                className="z-50 fixed top-0 left-0 w-full h-full bg-white opacity-80 flex items-center justify-center">
                <Spinner size={64} />
              </div>
            )
          }
          {
            !loading && typeof alreadyDone === 'boolean' && status && (
              <ApprovalStatus
                alreadyDone={alreadyDone}
                managerName={managerName}
                managerSurname={managerSurname}
                applicationName={applicationName}
                applicationSurname={applicationSurname}
                status={status} />
            )
          }
        </div>
      </div>
    </div>
  );

};

export default Approval;
