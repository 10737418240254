import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import HeadingText from '../../HeadingText/HeadingText';
import CheckIconWithBg from '../../Icons/CheckIconWithBg';
import Spinner from '../../Icons/Spinner';
import TriangleIconWithBg from '../../Icons/TriangleIconWithBg';

interface Props {
  loading: boolean;
  submitted: boolean;
  hasError: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onComplete: () => void;
}

const SubmitFeedbackDialog = ({
  loading,
  submitted,
  hasError,
  onConfirm,
  onClose,
  onComplete,
}: Props) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <>
      {/* Loader */}
      {loading && (
        <div className="flex min-h-[14rem] w-[28rem] max-w-full flex-col items-center justify-center gap-4">
          <Spinner size={48} />
          <HeadingText
            variant="heading"
            className="text-center"
          >
            {t('360.dialog.submitFeedback.loading')}
          </HeadingText>
        </div>
      )}
      {/* Submit Dialog */}
      {!loading && !hasError && !submitted && (
        <div className="flex w-[28rem] max-w-full flex-col items-center gap-2">
          <HeadingText
            variant="heading"
            className="text-center"
          >
            {t('360.dialog.submitFeedback.title')}
          </HeadingText>
          <p className="mb-10 max-w-[80%] text-center text-[#525252]">
            {t('360.dialog.submitFeedback.subtitle')}
          </p>
          <Button
            className="default lg:w-full"
            onClick={onConfirm}
          >
            {t('360.dialog.submitFeedback.button.confirm')}
          </Button>
          <Button
            className="secondary lg:w-full"
            onClick={onClose}
          >
            {t('360.dialog.submitFeedback.button.cancel')}
          </Button>
        </div>
      )}
      {/* Error dialog */}
      {!loading && hasError && (
        <div className="flex w-[21rem] max-w-full flex-col items-center gap-2">
          <TriangleIconWithBg bgcolor="#D5351F" />
          <HeadingText
            variant="heading"
            className="text-center"
          >
            {t('360.dialog.submitFeedback.error.title')}
          </HeadingText>
          <p className="mb-10 max-w-[80%] text-center text-[#525252]">
            {t('360.dialog.submitFeedback.error.subtitle')}
          </p>
          <Button
            className="default lg:w-full"
            onClick={onConfirm}
          >
            {t('360.dialog.submitFeedback.error.button.confirm')}
          </Button>
          <Button
            className="secondary lg:w-full"
            onClick={onClose}
          >
            {t('360.dialog.submitFeedback.error.button.cancel')}
          </Button>
        </div>
      )}
      {/* Submitted dialog */}
      {!loading && !hasError && submitted && (
        <div className="flex min-h-[10rem] w-[21rem] max-w-full flex-col items-center justify-center gap-4">
          <CheckIconWithBg
            color="#F4F9FA"
            bgcolor="#007A91"
          />
          <HeadingText
            variant="heading"
            className="text-center"
          >
            {t('360.dialog.submitFeedback.submitted.title')}
          </HeadingText>
          <Button
            className="secondary lg:w-full"
            onClick={onComplete}
          >
            {t('360.dialog.submitFeedback.submitted.button.confirm')}
          </Button>
        </div>
      )}
    </>
  );
};

export default SubmitFeedbackDialog;
