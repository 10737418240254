import clsx from 'clsx';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../App';
import SaveDraftDialog from '../../shared/components/Dialog/Contents/SaveDraftDialog';
import SubmitFeedbackDialog from '../../shared/components/Dialog/Contents/SubmitFeedbackDialog';
import Dialog from '../../shared/components/Dialog/Dialog';
import PointsOfContent from '../../shared/components/PointsOfContent/PointsOfContent';
import PointsOfFooter from '../../shared/components/PointsOfFooter/PointsOfFooter';
import PointsOfHeader from '../../shared/components/PointsOfHeader/PointsOfHeader';
import { TaskFeedback } from '../../shared/socket/socket.interfaces';
import { SET_QUERY_ERROR, STORE_FEEDBACK } from '../../store/exam/reducer';
import {
  getCompleteTask,
  getCurrentCluster,
  getHasError,
  getPointOfImprovementFeedback,
  getSelectedTask,
  getTaskFeedbacks,
  getVotedId,
} from '../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { actions } from '../../store/ui/reducer';
import { getNextLoading, getPrevLoading } from '../../store/ui/selectors';

const PointsOfImprovement = () => {
  // Hooks
  const { socket } = useSocketInstance();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  // State
  const [text, setText] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [feedbacksSubmitted, setFeedbacksSubmitted] = useState(false);
  // Selectors
  const complete_task = useAppSelector(getCompleteTask);
  const current_cluster = useAppSelector(getCurrentCluster);
  const prevLoading = useAppSelector(getPrevLoading);
  const nextLoading = useAppSelector(getNextLoading);
  const voted_id = useAppSelector(getVotedId);
  const feedbacks = useAppSelector(getTaskFeedbacks);
  const feedback = useAppSelector(getPointOfImprovementFeedback);
  const selected_task = useAppSelector(getSelectedTask);
  const hasError = useAppSelector(getHasError);
  // Computed
  const readonly = selected_task?.status === 'completed';

  const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => setText(e.target.value);

  const goBackHandler = (_: MouseEvent<HTMLButtonElement>): void => {
    dispatch(STORE_FEEDBACK({ type: 'improvement', text }));
    dispatch(actions.QUESTIONNAIRE_PREV_CLICKED());
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      socket.emit('feedback-prev', {
        voted_id,
        current_feedback: 'improvement',
        feedbacks: feedbacks.map((f: TaskFeedback) => ({
          ...f,
          text: f.type === 'improvement' ? text : f.text,
        })),
      });
      dispatch(actions.QUESTIONNAIRE_PREV_CLICKED());
    }, 500);
  };

  const submitHandler = (): void => {
    dispatch(SET_QUERY_ERROR(false));
    dispatch(STORE_FEEDBACK({ type: 'strength', text }));
    dispatch(actions.QUESTIONNAIRE_NEXT_CLICKED());
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      socket.emit('feedback-next', {
        voted_id,
        current_feedback: 'improvement',
        feedbacks: feedbacks.map((f: TaskFeedback) => ({
          ...f,
          text: f.type === 'improvement' ? text : f.text,
        })),
      });
      dispatch(actions.QUESTIONNAIRE_NEXT_CLICKED());
      setFeedbacksSubmitted(true);
    }, 500);
  };

  const saveDraftHandler = (): void => {
    socket.emit('save-draft', {
      voted_id,
      questions: [],
      feedbacks: feedbacks.map((f: TaskFeedback) => ({
        ...f,
        text: f.type === 'improvement' ? text : f.text,
      })),
    });
  };

  const completeTaskHandler = () => {
    setFeedbacksSubmitted(false);
    navigator(`/${job_post_alias}/${application_alias}/assessments`, { replace: true });
  };

  useEffect(() => {
    dispatch(actions.QUESTIONNAIRE_DISABLE_PREV_LOADING());
    dispatch(actions.QUESTIONNAIRE_DISABLE_NEXT_LOADING());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (feedback && feedback.text) {
      setText(feedback.text);
    }
  }, [feedback]);

  return (
    <div className={clsx('flex h-full flex-col items-center justify-start', 'lg:justify-center')}>
      <div className={clsx('flex h-full w-full items-center justify-center', 'lg:max-w-[90%]')}>
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-start bg-[#FFFFFF]',
            'lg:max-h-[886px] lg:rounded-[8px] lg:shadow-cust-1'
          )}
        >
          {/* Header */}
          <PointsOfHeader
            fullname={current_cluster.name}
            currentQuestion={5}
            totalQuestion={complete_task.length + feedbacks.length}
          />
          {/* Content */}
          <PointsOfContent
            title={t('360.pointsOfImprovement.textarea.title')}
            subtitle={t('360.pointsOfImprovement.textarea.subtitle')}
            readonly={readonly}
            text={text}
            onChange={changeHandler}
          />
          {/* Footer */}
          <PointsOfFooter
            currentQuestion={5}
            totalQuestion={complete_task.length + feedbacks.length}
            readonly={readonly}
            canGoBack={true}
            canGoNext={true}
            goBackLabel={t('360.button.back')}
            goNextLabel={readonly ? t('360.button.done') : t('360.button.send')}
            goBackLoading={prevLoading}
            goNextLoading={nextLoading}
            onSaveDraft={() => setIsDialogOpen(true)}
            onGoBack={goBackHandler}
            onGoNext={readonly ? completeTaskHandler : () => setIsSubmitDialogOpen(true)}
          />
        </div>
      </div>
      {/* Submit feedback dialog box */}
      <Dialog isOpen={isSubmitDialogOpen}>
        <SubmitFeedbackDialog
          loading={nextLoading}
          hasError={hasError}
          submitted={feedbacksSubmitted}
          onClose={() => setIsSubmitDialogOpen(false)}
          onConfirm={submitHandler}
          onComplete={completeTaskHandler}
        />
      </Dialog>
      {/* Save to draft dialog box */}
      <Dialog isOpen={isDialogOpen}>
        <SaveDraftDialog
          onClose={() => setIsDialogOpen(false)}
          onConfirm={() => saveDraftHandler()}
        />
      </Dialog>
    </div>
  );
};

export default PointsOfImprovement;
