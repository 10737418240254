import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
  bgcolor?: string;
}

const CheckIconWithBg = ({ ...props }: Props) => {
  return (
    <svg
      width={props.width ?? 40}
      height={props.height ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={props.width ?? 40}
        height={props.height ?? 40}
        rx={20}
        fill={props.bgcolor ?? '#000'}
      />
      <path
        d="M28.229 14.275a.934.934 0 010 1.325L17.916 25.916a.934.934 0 01-1.324 0l-5.317-5.313A.937.937 0 0112.6 19.28l4.649 4.649 9.652-9.653a.934.934 0 011.325 0h.003z"
        fill={props.color ?? '#fff'}
      />
    </svg>
  );
};

export default CheckIconWithBg;
