import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import HeadingText from '../HeadingText/HeadingText';
import LinkIcon from '../Icons/LinkIcon';

interface Props {
  onCopyLink: () => void;
}

const ShareLink = ({ onCopyLink }: Props) => {

  const { t } = useTranslation();

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const [disabled, setDisabled] = useState<boolean>(false);

  const clickHandler = (): void => {
    if (!disabled) {
      setDisabled(true);
      onCopyLink();
      timeoutRef.current = setTimeout(() => {
        clearTimeout(timeoutRef.current);
        setDisabled(false);
      }, 1000);
    }
  };

  return (
    <div
      className="w-full max-w-[600px] p-5 flex flex-col items-center justify-center">

      <HeadingText
        variant="primary-small"
        className="font-semibold mb-2 text-center">{t('360.shareLink.title')}</HeadingText>

      <HeadingText
        variant="primary-small"
        className="font-normal mb-4 text-[#666666] text-center">{t('360.shareLink.subtitle')}</HeadingText>

      <Button
        disabled={disabled}
        className="bg-[#111111] text-white hover:bg-[#111111c8]"
        onClick={clickHandler}>
        <LinkIcon />
        <span className="ml-2 text-center">
          {!disabled && t('360.shareLink.button')}
          {disabled && t('360.shareLink.button.disabled')}
        </span>
      </Button>

      <HeadingText
        variant="primary-small"
        className="w-full font-normal mt-4 text-[#666666] text-left">{t('360.shareLink.description.line1')}</HeadingText>

      <ul className="list-disc">
        <li>
          <HeadingText
            variant="primary-small"
            className="font-normal mt-2 text-[#666666] text-left">{t('360.shareLink.description.line2')}</HeadingText>
        </li>
        <li>
          <HeadingText
            variant="primary-small"
            className="font-normal mt-2 text-[#666666] text-left">{t('360.shareLink.description.line3')}</HeadingText>
        </li>
      </ul>

      <HeadingText
        variant="primary-small"
        className="font-normal mt-4 text-[#666666] text-left">{t('360.shareLink.description.line4')}</HeadingText>

    </div>
  );

};

export default ShareLink;
