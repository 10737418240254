import clsx from 'clsx';
import { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useState } from 'react';

interface Props {
  loading: boolean;
  onChange: (value: string) => void;
}

const SixDigitCode = ({ loading, onChange }: Props) => {
  const [code, setCode] = useState<string[]>(['', '', '', '', '', '']);

  const populateNext = (
    el: HTMLInputElement,
    data: string,
    result: string[] = ['', '', '', '', '', '']
  ): void => {
    const allowedValue: boolean = isValidInput(data[0]);
    if (!allowedValue) {
      return;
    }
    const target: string = el.name;
    const index: number = +target.split('six-digit-code-')[1];
    const newResult = [...result];
    newResult[index] = data[0];
    const newData: string = data.substring(1);
    if (el.nextElementSibling && newData.length) {
      populateNext(el.nextElementSibling as HTMLInputElement, newData, newResult);
    } else {
      setCode(newResult);
    }
  };

  const splitNumber = (target: HTMLInputElement, data: string): void => {
    if (data.length === 1) return;
    populateNext(target, data);
  };

  const isValidInput = (input: string): boolean => /^\d*\.?\d*$/.test(input);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const allowedValue: boolean = isValidInput(e.target.value);
    if (!allowedValue) {
      return;
    }
    const target: string = e.target.name;
    const index: number = +target.split('six-digit-code-')[1];
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);
    if (!e.target.value) {
      const previousElement: HTMLInputElement | null = document.querySelector(
        `input[name=six-digit-code-${index - 1}]`
      );
      if (previousElement) {
        previousElement.focus();
      }
      return;
    }
    const nextElement: HTMLInputElement | null = document.querySelector(
      `input[name=six-digit-code-${index + 1}]`
    );
    if (nextElement) {
      nextElement.focus();
    }
    return;
  };

  const keyupHandler = (e: KeyboardEvent<HTMLInputElement>): void => {
    const currentElement: string = (e.target as HTMLInputElement).name;
    const index: number = +currentElement.split('six-digit-code-')[1];
    if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
      const target: number = e.key === 'ArrowLeft' ? index - 1 : index + 1;
      const targetElement: HTMLInputElement | null = document.querySelector(
        `input[name=six-digit-code-${target}]`
      );
      if (targetElement && document.activeElement !== targetElement) {
        targetElement.focus();
      }
    }
  };

  const pasteHandler = (e: ClipboardEvent<HTMLInputElement>): void => {
    const data: string = e.clipboardData.getData('text');
    e.preventDefault();
    return splitNumber(e.target as HTMLInputElement, data);
  };

  useEffect(() => {
    if (code.length === 6) {
      onChange(code.join(''));
    }
  }, [code, onChange]);

  return (
    <div
      className={clsx(
        "w-full h-[48px] flex my-6 flex-row items-stretch justify-between",
        "lg:h-[64px]"
      )}>
      <input
        className={clsx(
          "flex mx-1 max-w-[48px] border border-[#DDDDDD] rounded-lg text-center text-xl font-normal",
          "lg:max-w-[64px]"
        )}
        name="six-digit-code-0"
        disabled={loading}
        value={code[0]}
        type="tel"
        pattern="[0-9]*"
        required
        minLength={1}
        maxLength={1}
        onChange={changeHandler}
        onKeyUp={keyupHandler}
        onPaste={pasteHandler}
      />
      <input
        className={clsx(
          "flex mx-1 max-w-[48px] border border-[#DDDDDD] rounded-lg text-center text-xl font-normal",
          "lg:max-w-[64px]"
        )}
        name="six-digit-code-1"
        disabled={loading}
        value={code[1]}
        type="tel"
        pattern="[0-9]*"
        required
        minLength={1}
        maxLength={1}
        onChange={changeHandler}
        onKeyUp={keyupHandler}
      />
      <input
        className={clsx(
          "flex mx-1 max-w-[48px] border border-[#DDDDDD] rounded-lg text-center text-xl font-normal",
          "lg:max-w-[64px]"
        )}
        name="six-digit-code-2"
        disabled={loading}
        value={code[2]}
        type="tel"
        pattern="[0-9]*"
        required
        minLength={1}
        maxLength={1}
        onChange={changeHandler}
        onKeyUp={keyupHandler}
      />
      <input
        className={clsx(
          "flex mx-1 max-w-[48px] border border-[#DDDDDD] rounded-lg text-center text-xl font-normal",
          "lg:max-w-[64px]"
        )}
        name="six-digit-code-3"
        disabled={loading}
        value={code[3]}
        type="tel"
        pattern="[0-9]*"
        required
        minLength={1}
        maxLength={1}
        onChange={changeHandler}
        onKeyUp={keyupHandler}
      />
      <input
        className={clsx(
          "flex mx-1 max-w-[48px] border border-[#DDDDDD] rounded-lg text-center text-xl font-normal",
          "lg:max-w-[64px]"
        )}
        name="six-digit-code-4"
        disabled={loading}
        value={code[4]}
        type="tel"
        pattern="[0-9]*"
        required
        minLength={1}
        maxLength={1}
        onChange={changeHandler}
        onKeyUp={keyupHandler}
      />
      <input
        className={clsx(
          "flex mx-1 max-w-[48px] border border-[#DDDDDD] rounded-lg text-center text-xl font-normal",
          "lg:max-w-[64px]"
        )}
        name="six-digit-code-5"
        disabled={loading}
        value={code[5]}
        type="tel"
        pattern="[0-9]*"
        required
        minLength={1}
        maxLength={1}
        onChange={changeHandler}
        onKeyUp={keyupHandler}
      />
    </div>
  );
};

export default SixDigitCode;
