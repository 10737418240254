import { useTranslation } from 'react-i18next';
import ErrorWarningIcon from '../Icons/ErrorWarningIcon';

const StakeholderNotAllowed = () => {

  const { t } = useTranslation();

  return (
    <div className='p-4 bg-orange-100 text-orange-950 mt-6 rounded-lg flex items-start flex-row gap-3'>
      <ErrorWarningIcon />
      <h3>{t('360.other-stakeholder.blocked')}</h3>
    </div>
  );

};

export default StakeholderNotAllowed;
