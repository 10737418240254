import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import HeadingText from '../../shared/components/HeadingText/HeadingText';
import LockerIcon from '../../shared/components/Icons/LockerIcon';

const AssessmentsClosed = () => {

  const { t } = useTranslation();

  return (
    <div className="m-[auto] flex h-full w-full items-center justify-center">
      <div
        className={clsx(
          'flex h-full w-full max-w-5xl flex-col items-center justify-center self-center rounded-lg bg-[#ffffff] px-4',
          'lg:max-h-[580px] lg:shadow-cust-1'
        )}
      >
        <div className="mb-8 flex h-[80px] w-[80px] items-center justify-center rounded-full bg-[#FFF8C5]">
          <LockerIcon />
        </div>
        <div className="max-w-[450px] text-center">
          <HeadingText className="mb-2 font-bold">{t('360.assessmentClosed.title')}</HeadingText>
          <HeadingText variant="secondary">{t('360.assessmentClosed.subtitle')}</HeadingText>
        </div>
      </div>
    </div>
  );

};

export default AssessmentsClosed;
