import Spinner from '../Icons/Spinner';

interface Props {
  size?: number;
}

const PageLoader = ({ size = 64 }: Props) => {
  return (
    <div className="pointer-events-none absolute z-[100] flex h-full w-full items-center justify-center bg-white">
      <div style={{ width: `${size}px`, height: `${size}px` }}>
        <Spinner size={size} />
      </div>
    </div>
  );
};

export default PageLoader;
