import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import ApplicationAlias from './features/ApplicationAlias/ApplicationAlias';
import Approval from './features/Approval/Approval';
import Assessments from './features/Assessments/components/Assessments';
import AssessmentsClosed from './features/AssessmentsClosed/AssessmentsClosed';
import Guest from './features/Guest/components/Guest';
import Intro from './features/Intro/components/Intro';
import JobPostAlias from './features/JobPostAlias/JobPostAlias';
import NotFound from './features/NotFound/NotFound';
import OTP from './features/OTP/OTP';
import PointsOfImprovement from './features/PointsOfImprovement/PointsOfImprovement';
import PointsOfStrength from './features/PointsOfStrength/PointsOfStrength';
import Task from './features/Task/components/Task';
import TechnicalIssue from './features/TechnicalIssue/TechnicalIssue';

const routes: ReturnType<typeof createBrowserRouter> = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/:job_post_alias',
        element: <JobPostAlias />,
        children: [
          {
            index: true,
            element: <Guest />,
          },
          {
            path: ':application_alias',
            element: <ApplicationAlias />,
            children: [
              {
                path: 'otp',
                element: <OTP />
              },
              {
                path: 'intro',
                element: <Intro />,
              },
              {
                path: 'assessments',
                element: <Assessments />
              },
              {
                path: 'task',
                element: <Task />
              },
              {
                path: 'points-of-strength',
                element: <PointsOfStrength />
              },
              {
                path: 'points-of-improvement',
                element: <PointsOfImprovement />
              },
              // {
              //   path: 'approval/:relation_id',
              //   element: <Approval />
              // },
              {
                path: 'not-found',
                element: <NotFound />,
              },
              {
                path: 'technical-issue',
                element: <TechnicalIssue />,
              },
            ],
          },
        ],
      },
      {
        path: '/approval/:voted_alias',
        element: <Approval />
      }
    ]
  },
  {
    path: 'assessments-closed',
    element: <AssessmentsClosed />
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default routes;
