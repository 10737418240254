import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import HeadingText from '../HeadingText/HeadingText';

interface Props {
  title: string;
  subtitle: string;
  readonly: boolean;
  text: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}
const PointsOfContent = ({ title, subtitle, readonly, text, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="h-full w-full overflow-auto bg-white">
      <div className="flex h-full flex-col p-6">
        <HeadingText
          variant="primary-small"
          className="mb-2 font-medium"
        >
          {title}
        </HeadingText>

        <HeadingText
          variant="primary-small"
          className="mb-2 text-sm text-[#475467]"
        >
          {subtitle}
        </HeadingText>

        <textarea
          className="h-full max-h-[574px] min-h-[200px] w-full resize-none rounded-xl border-2 border-[#cdcdcd] bg-white p-2 disabled:opacity-50"
          name="points-of-strenght"
          id="points-of-strenght"
          disabled={readonly}
          maxLength={1000}
          placeholder={t('360.pointsOfStrenght.textarea.placeholder')}
          value={text}
          onChange={onChange}
        />

        <div className="mr-2 mt-2 text-right text-sm italic">
          <span className="text-[#475467]">{text.length}</span>
          <span className="text-[#475467]">/1000</span>
        </div>
      </div>
    </div>
  );
};

export default PointsOfContent;
