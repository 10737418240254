import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  surname: string;
}

const DescriptionText = ({ name, surname }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<div class="mb-6 lg:mb-0 text-md text-[#101828] font-normal leading-5">
          ${t('360.intro.welcomeText', {
            name,
            surname,
            technicalSupportEmail: 'support_360feedback@unicredit.eu', // REFACTOR: Maybe put in .env?
          })}
        </div>`,
      }}
    />
  );
};

export default DescriptionText;
