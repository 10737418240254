import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import LanguageSelector from '../../../shared/components/LanguageSelector/LanguageSelector';
import ClusterBadge from './ClusterBadge';

interface Props {
  fullname: string;
  currentQuestion: number;
  totalQuestion: number;
  cluster: string;
}

const TaskHeader = ({ fullname, currentQuestion, totalQuestion, cluster }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex w-full flex-row items-center justify-between border-b border-b-[#D0D5DD] px-4 py-2 shadow',
        'lg:px-6 lg:py-2'
      )}
    >
      <div>
        <HeadingText
          variant="primary-small"
          className="font-semibold"
        >
          {fullname}
        </HeadingText>
        <div className="flex items-center gap-1">
          <ClusterBadge cluster={cluster} />
          <HeadingText
            className={clsx('sm:block', 'md:hidden')}
            variant="secondary"
          >
            {t('360.task.subtitle', { currentQuestion, totalQuestion })}
          </HeadingText>
        </div>
      </div>
      <div className="flex items-center justify-start">
        <LanguageSelector />
      </div>
    </div>
  );
};

export default TaskHeader;
