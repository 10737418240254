import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../shared/components/Icons/CheckIcon';

interface Props {
  managerName: string;
  managerSurname: string;
  applicationName: string;
  applicationSurname: string;
  status: 'approved' | 'rejected';
}

const Approved = ({ managerName, managerSurname, applicationName, applicationSurname, status }: Props) => {

  const { t } = useTranslation();

  return (
    <>
      <div className={clsx(
        'w-[48px] h-[48px] p-2 rounded-full flex items-center justify-center bg-[#DAFBE1] mb-4'
      )}>
        <CheckIcon color='#1F883D' />
      </div>
      <div dangerouslySetInnerHTML={{
        __html: t(`360.approval.${status}`, {
          manager_name: managerName,
          manager_surname: managerSurname,
          application_name: applicationName,
          application_surname: applicationSurname,
          status: t(`360.approval.status.${status}`)
        })
      }} />
    </>
  );

};

export default Approved;
