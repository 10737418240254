
const GermanFlagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={20}
      fill="none"
    >
      <rect width={28} height={20} fill="#fff" rx={2} />
      <mask
        id="a"
        width={28}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <rect width={28} height={20} fill="#fff" rx={2} />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#262626"
          fillRule="evenodd"
          d="M0 6.667h28V0H0v6.667Z"
          clipRule="evenodd"
        />
        <g filter="url(#b)">
          <path
            fill="#F01515"
            fillRule="evenodd"
            d="M0 13.333h28V6.667H0v6.666Z"
            clipRule="evenodd"
          />
        </g>
        <g filter="url(#c)">
          <path
            fill="#FFD521"
            fillRule="evenodd"
            d="M0 20h28v-6.667H0V20Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <filter
          id="b"
          width={28}
          height={6.667}
          x={0}
          y={6.667}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_663_5220"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_663_5220"
            result="shape"
          />
        </filter>
        <filter
          id="c"
          width={28}
          height={6.667}
          x={0}
          y={13.333}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_663_5220"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_663_5220"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default GermanFlagIcon;
