
const LinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={14}
      fill="none"
    >
      <path
        fill="#fff"
        d="M4.112 3.707a3.804 3.804 0 0 1 5.554 5.195l-.14.161a.636.636 0 0 1-.957-.836l.143-.16a2.536 2.536 0 0 0-.117-3.463C7.605 3.59 6 3.59 4.987 4.604L2.012 7.6a2.536 2.536 0 0 0 0 3.586c.944.92 2.46.995 3.462.117l.164-.143a.633.633 0 0 1 .894.06.633.633 0 0 1-.058.895l-.164.143a3.804 3.804 0 0 1-5.195-.175c-1.487-1.484-1.487-3.917 0-5.38l2.997-2.997Zm7.776 6.586a3.806 3.806 0 0 1-5.382 0 3.783 3.783 0 0 1-.175-5.195l.124-.143a.636.636 0 0 1 .958.836l-.125.143a2.536 2.536 0 0 0 3.704 3.463L13.988 6.4a2.535 2.535 0 0 0-3.462-3.702l-.164.141a.634.634 0 0 1-.836-.955l.164-.142a3.804 3.804 0 0 1 5.195 5.554l-2.997 2.997Z"
      />
    </svg>
  );
};

export default LinkIcon;
