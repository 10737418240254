
interface Props {
  cluster: string;
}

const ClusterBadge = ({ cluster }: Props) => {

  return (
    <div
      className="pr-2 pt-1 pb-1 ">
      <p
        className="text-[#007a91] m-0 text-sm tracking-tighter uppercase font-bold">{cluster}</p>
    </div>
  );

};

export default ClusterBadge;
