import { useEffect, useRef, useState } from 'react';

interface Props {
  video: string;
  thumbnail?: string;
}

const VideoPlayer = ({ video, thumbnail }: Props) => {
  const videoPlayerRef: any = useRef<HTMLVideoElement>();

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const clickHandler = (): void => setIsPlaying((state) => !state);

  useEffect(() => {
    if (videoPlayerRef && videoPlayerRef.current) {
      isPlaying ? videoPlayerRef.current.play() : videoPlayerRef.current.pause();
    }
  }, [videoPlayerRef, isPlaying]);

  useEffect(() => {
    if (videoPlayerRef && videoPlayerRef.current && video) {
      videoPlayerRef.current.load();
    }
  }, [videoPlayerRef, video]);

  return (
    <div className="h-100 relative mx-auto my-0 w-full overflow-hidden rounded-lg bg-black">
      <video
        className="h-100 max-w-100 max-h-100 mx-auto block cursor-pointer"
        controls
        ref={videoPlayerRef}
        poster={thumbnail}
        onClick={clickHandler}
      >
        <source src={video} />
      </video>
    </div>
  );
};

export default VideoPlayer;
