import { useTranslation } from 'react-i18next';
import { Q360Task, Q360TaskGroup } from '../../../interfaces';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import Assessment from './Assessment';

interface Props {
  title: Q360TaskGroup;
  group: Q360Task[];
  total: number;
  completed: number;
  onClick: (id: number) => void;
}

const AssessmentsGroup = ({ title, group, total, completed, onClick }: Props) => {

  const { t } = useTranslation();

  return (
    <div
      className="my-6">
      <div
        className="mb-4 py-2 px-4">
        <HeadingText
          variant='primary-small'
          className="font-bold">{t(`360.assessments.category.${title}`)} ({completed}/{total})</HeadingText>
      </div>
      {
        group.map((assessment: Q360Task) => (
          <Assessment
            key={`group-${title}-assessment-${assessment.id}`}
            assessmentId={assessment.id}
            title={`${assessment.name} ${assessment.surname}`}
            status={assessment.status}
            onClick={onClick} />
        ))
      }
    </div>
  );

};

export default AssessmentsGroup;
