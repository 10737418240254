export const formatDate = (
  date: string,
  withHours = false,
  language: string = navigator.language
): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (withHours) {
    options.hour = 'numeric';
    options.minute = 'numeric';
  }
  const dateTime: Intl.DateTimeFormat = new Intl.DateTimeFormat(language, options);
  return dateTime.format(new Date(date));
};

export const formatDigitDate = (
  date: string,
  withHours = false,
  language: string = navigator.language
): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  if (withHours) {
    options.hour = 'numeric';
    options.minute = 'numeric';
  }
  const dateTime: Intl.DateTimeFormat = new Intl.DateTimeFormat(language, options);
  return dateTime.format(new Date(date));
};
