import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import HeadingText from '../HeadingText/HeadingText';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

interface Props {
  fullname: string;
  currentQuestion: number;
  totalQuestion: number;
}

const PointsOfHeader = ({ fullname, currentQuestion, totalQuestion }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        'flex min-h-[53px] w-full flex-row items-center justify-between border-b border-b-[#D0D5DD] px-4 py-2 shadow',
        'lg:px-6 lg:py-2'
      )}
    >
      <div className="flex flex-col">
        <HeadingText
          variant="primary-small"
          className="font-semibold"
        >
          {fullname}
        </HeadingText>
        <HeadingText
          className={clsx('md:hidden')}
          variant="secondary"
        >
          {t('360.task.subtitle', { currentQuestion, totalQuestion })}
        </HeadingText>
      </div>
      <div className="flex items-center justify-start">
        <LanguageSelector />
      </div>
    </div>
  );
};

export default PointsOfHeader;
