import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import { getApplication } from '../../../store/exam/selectors';
import AssessmentsHeader from './AssessmentsHeader';
import AssessmentsList from './AssessmentsList';

const Assessments = () => {

  const { socket } = useSocketInstance();

  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  const { id: voter_id } = useSelector(getApplication);

  const [taskSelected, setTaskSelected] = useState<boolean>(false);

  const clickHandler = (id: number): void => {
    setTaskSelected(true);
    socket.emit('request-task', { voter_id, voted_id: id });
  };

  useEffect(() => {
    socket.emit('whoami', { job_post_alias, application_alias, silent: true });
  }, []);

  return (
    <div className={clsx(
      'h-full flex flex-col items-center justify-start',
      'lg:justify-center'
    )}>
      <div className={clsx(
        'flex h-full w-full items-center justify-center',
        'lg:mb-8 lg:h-auto lg:max-w-[90%]'
      )}>
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-start bg-[#FFFFFF]',
            'lg:justify-center lg:h-auto lg:rounded-[8px] lg:shadow-cust-1'
          )}
        >
          {/* Header */}
          <AssessmentsHeader />

          {/* Assessments List */}
          <AssessmentsList
            loading={taskSelected}
            onClick={clickHandler} />

        </div>
      </div>
    </div>
  );

};

export default Assessments;
