import { getCompany, getJobPost } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import HeadingText from '../HeadingText/HeadingText';

interface Props {
  logoOnly?: boolean;
}

const JobHeader = ({ logoOnly = false }: Props) => {
  const company = useAppSelector(getCompany);
  const job_post = useAppSelector(getJobPost);

  return (
    <div className="flex w-full flex-row items-stretch justify-start lg:px-0">
      <div
        className="mr-4 max-w-[126px]">
        <img src="https://uc360-status.algointerview.it/unicredit_logo.png" alt="Unicredit official logo - small version" />
      </div>
      {!logoOnly && (
        <div className="flex flex-col items-start justify-center">
          <HeadingText className="font-bold">{job_post.name}</HeadingText>
          <HeadingText variant="secondary">{company.name}</HeadingText>
        </div>
      )}
    </div>
  );
};

export default JobHeader;
