import { useEffect } from 'react';
import { useAppDispatch } from '../../../store/store';
import { actions } from '../../../store/ui/reducer';
import IntroBox from './IntroBox';
import { useSocketInstance } from '../../../App';

const Intro = () => {
  const { socket } = useSocketInstance();

  const dispatch = useAppDispatch();

  const submitHandler = (): void => {
    dispatch(actions.INTRO_SUBMIT());
    socket.emit('policies-accepted');
  };

  useEffect(() => {
    dispatch(actions.INTRO_START());
  }, []);

  return <IntroBox onSubmit={submitHandler} />;
};

export default Intro;
