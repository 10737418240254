import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  Application,
  Company,
  Job_Post,
  Language,
  Q360TaskList,
  Questionnaire,
  Soft_Skill,
} from '../../interfaces';
import {
  AllowedDomainsRetrievedPayload,
  ClusterQuestion,
  ExamFoundPayload,
  LanguagesRetrievedPayload,
  ManagerApproval,
  ManagerApprovalSubmitPayload,
  QuestionnaireNextAuthorizedPayload,
  QuestionnairePrevAuthorizedPayload,
  StoreFeedbackPayload,
  TaskCluster,
  TaskFeedback,
  TaskRetrievedPayload,
} from '../../shared/socket/socket.interfaces';

export interface ExamState {
  loading: boolean;
  hasError: boolean;
  available_languages: Pick<Language, 'id' | 'label' | 'iso_code'>[];
  allowed_domains: string[];
  company: Company;
  job_post: Job_Post;
  application: Application;
  soft_skills: Soft_Skill[];
  questionnaires: Questionnaire[];
  q360_task_list: Q360TaskList;
  q360_selected_task: TaskCluster[];
  next_step: TaskCluster;
  feedbacks: TaskFeedback[];
  completed_chunks: number;
  policies_accepted: boolean;
  can_share: boolean;
  manager_approval: ManagerApproval;
}

const initialState: ExamState = {
  loading: true,
  hasError: false,
  available_languages: [],
  allowed_domains: [],
  company: {} as Company,
  job_post: {} as Job_Post,
  application: {} as Application,
  soft_skills: [],
  questionnaires: [],
  q360_task_list: {} as Q360TaskList,
  q360_selected_task: [] as TaskCluster[],
  next_step: {} as TaskCluster,
  feedbacks: [] as TaskFeedback[],
  completed_chunks: 0,
  policies_accepted: false,
  can_share: false,
  manager_approval: {
    loading: true,
    voted: {
      name: '',
      surname: '',
      email: '',
    },
    manager: {
      name: '',
      surname: '',
      email: '',
    },
    alreadyVoted: false,
  } as ManagerApproval,
};

const slice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    // new actions
    LANGUAGES_RETRIEVED: (state, action: PayloadAction<LanguagesRetrievedPayload>) => ({
      ...state,
      available_languages: [...action.payload.languages],
    }),
    ALLOWED_DOMAINS_RETRIEVED: (state, action: PayloadAction<AllowedDomainsRetrievedPayload>) => ({
      ...state,
      allowed_domains: [...action.payload.allowed_domains],
    }),
    TASK_RETRIEVED: (state, action: PayloadAction<TaskRetrievedPayload>) => ({
      ...state,
      q360_selected_task: [...action.payload.task],
      next_step: {
        ...action.payload.task[0],
      },
      feedbacks: [...action.payload.feedbacks],
    }),

    // old actions
    RETRIEVE_EXAM: (state, action: PayloadAction<ExamFoundPayload>) => ({
      ...state,
      ...action.payload,
      loading: false,
    }),
    SET_NEXT_STEP: (state, action: PayloadAction<TaskCluster>) => ({
      ...state,
      next_step: {
        ...action.payload,
      },
    }),
    SELECT_ANSWER: (state, action: PayloadAction<{ question_id: number; vote: number }>) => {
      let new_selected_task: TaskCluster[] = [];
      const current_cluster_name: string = state.next_step.cluster;
      const task_index: number = state.q360_selected_task.findIndex(
        (t: TaskCluster) => t.cluster === current_cluster_name
      );
      let question_index: number;
      if (task_index > -1) {
        question_index = state.q360_selected_task[task_index].questions.findIndex(
          (q: ClusterQuestion) => q.id === action.payload.question_id
        );
        if (question_index > -1) {
          new_selected_task = state.q360_selected_task.map((c: TaskCluster, c_index: number) => {
            if (c_index === task_index) {
              return {
                ...c,
                questions: c.questions.map((q: ClusterQuestion, q_index: number) => {
                  if (q_index === question_index) {
                    return {
                      ...q,
                      score: action.payload.vote,
                    };
                  }
                  return q;
                }),
              };
            }
            return c;
          });
        }
      }
      return {
        ...state,
        q360_selected_task: [...new_selected_task],
        next_step: {
          ...new_selected_task[task_index],
        },
      };
    },
    QUESTIONNAIRE_PREV: (state, action: PayloadAction<QuestionnairePrevAuthorizedPayload>) => {
      const current_index: number = state.q360_selected_task.findIndex(
        (t: TaskCluster) => t.cluster === state.next_step.cluster
      );
      if (current_index > -1) {
        const prev_index: number = current_index - 1;
        if (state.q360_selected_task[prev_index]) {
          return {
            ...state,
            q360_selected_task: state.q360_selected_task.map((q: TaskCluster) => {
              if (q.cluster === state.next_step.cluster) {
                return {
                  ...q,
                  questions: [...action.payload.questions],
                };
              }
              return q;
            }),
            next_step: {
              ...state.q360_selected_task[prev_index],
              questions: [...state.q360_selected_task[prev_index].questions],
            },
          };
        }
      }
      return {
        ...state,
      };
    },
    QUESTIONNAIRE_NEXT: (state, action: PayloadAction<QuestionnaireNextAuthorizedPayload>) => {
      const current_index: number = state.q360_selected_task.findIndex(
        (t: TaskCluster) => t.cluster === state.next_step.cluster
      );
      if (current_index > -1) {
        const next_index: number = current_index + 1;
        if (state.q360_selected_task[next_index]) {
          return {
            ...state,
            q360_selected_task: state.q360_selected_task.map((q: TaskCluster) => {
              if (q.cluster === state.next_step.cluster) {
                return {
                  ...q,
                  questions: [...action.payload.questions],
                };
              }
              return q;
            }),
            next_step: {
              ...state.q360_selected_task[next_index],
              questions: [...state.q360_selected_task[next_index].questions],
            },
          };
        }
      }
      return {
        ...state,
      };
    },
    STORE_FEEDBACK: (state, action: PayloadAction<StoreFeedbackPayload>) => {
      return {
        ...state,
        feedbacks: state.feedbacks.map((f: TaskFeedback) => ({
          ...f,
          text: f.type === action.payload.type ? action.payload.text : f.text,
        })),
      };
    },
    DISABLE_LOADING: (state, _: PayloadAction<void>) => ({
      ...state,
      loading: false,
    }),
    VIDEO_INTERVIEW_START: (state) => ({
      ...state,
      completed_chunks: 0,
    }),
    UPDATE_COMPLETED_CHUNKS: (state) => ({
      ...state,
      completed_chunks: state.completed_chunks + 1,
    }),
    MANAGER_APPROVAL_SUBMITTED: (state, action: PayloadAction<ManagerApprovalSubmitPayload>) => ({
      ...state,
      manager_approval: {
        loading: false,
        ...action.payload,
      },
    }),
    SET_QUERY_ERROR: (state, action) => ({
      ...state,
      loading: false,
      hasError: action.payload,
    }),
  },
});

export default slice.reducer;

export const {
  LANGUAGES_RETRIEVED,
  ALLOWED_DOMAINS_RETRIEVED,
  TASK_RETRIEVED,
  RETRIEVE_EXAM,
  SET_NEXT_STEP,
  SELECT_ANSWER,
  QUESTIONNAIRE_PREV,
  QUESTIONNAIRE_NEXT,
  STORE_FEEDBACK,
  DISABLE_LOADING,
  VIDEO_INTERVIEW_START,
  UPDATE_COMPLETED_CHUNKS,
  MANAGER_APPROVAL_SUBMITTED,
  SET_QUERY_ERROR,
} = slice.actions;
