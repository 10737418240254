
const ItalianFlagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={20}
      fill="none"
    >
      <rect
        width={27.5}
        height={19.5}
        x={0.25}
        y={0.25}
        fill="#fff"
        stroke="#F5F5F5"
        strokeWidth={0.5}
        rx={1.75}
      />
      <mask
        id="a"
        width={28}
        height={20}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <rect
          width={27.5}
          height={19.5}
          x={0.25}
          y={0.25}
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
          rx={1.75}
        />
      </mask>
      <g mask="url(#a)">
        <path fill="#E43D4C" d="M18.666 0h9.333v20h-9.333z" />
        <path
          fill="#1BB65D"
          fillRule="evenodd"
          d="M0 20h9.333V0H0v20Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default ItalianFlagIcon;
