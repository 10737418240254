import clsx from 'clsx';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../Icons/Spinner';

interface ButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean;
}

const Button = ({ loading = false, children, ...props }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      disabled={props.disabled || loading}
      className={clsx('btn', props.className)}
    >
      {loading && (
        <>
          <Spinner /> <span className="ml-2">{t('button.loading.text')}</span>
        </>
      )}
      {!loading && children}
    </button>
  );
};

export default Button;
