import clsx from 'clsx';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import HeadingText from '../HeadingText/HeadingText';
import Spinner from '../Icons/Spinner';

interface Props {
  currentQuestion: number;
  totalQuestion: number;
  readonly: boolean;
  canGoBack: boolean;
  canGoNext: boolean;
  goBackLabel: string;
  goNextLabel: string;
  goBackLoading: boolean;
  goNextLoading: boolean;
  onSaveDraft: (_: MouseEvent<HTMLButtonElement>) => void;
  onGoBack: (_: MouseEvent<HTMLButtonElement>) => void;
  onGoNext: (_: MouseEvent<HTMLButtonElement>) => void;
}

const PointsOfFooter = ({
  currentQuestion,
  totalQuestion,
  readonly,
  canGoBack,
  canGoNext,
  goBackLabel,
  goNextLabel,
  goBackLoading,
  goNextLoading,
  onSaveDraft,
  onGoBack,
  onGoNext,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex w-full flex-row items-center border-t border-t-[#D0D5DD] px-4 py-2 shadow',
        { 'justify-between': !readonly, 'justify-end': readonly },
        'lg:px-6 lg:py-2'
      )}
    >
      {!readonly && (
        <div className="flex flex-1 flex-row">
          <Button
            className="secondary !w-auto"
            onClick={onSaveDraft}
          >
            {t('360.task.buttons.save-draft.label')}
          </Button>
        </div>
      )}
      <div className="flex flex-row">
        <HeadingText
          className={clsx('mr-4 hidden items-center md:flex md:min-w-[100px] md:justify-end')}
          variant="secondary"
        >
          {t('360.task.subtitle', { currentQuestion, totalQuestion })}
        </HeadingText>
        <Button
          disabled={goBackLoading || goNextLoading || !canGoBack}
          className="secondary mr-2"
          onClick={onGoBack}
        >
          {!goBackLoading && goBackLabel}
          {goBackLoading && (
            <>
              <div className="mr-2">
                <Spinner size={16} />
              </div>{' '}
              {goBackLabel}
            </>
          )}
        </Button>
        <Button
          disabled={goBackLoading || goNextLoading || !canGoNext}
          className="default"
          onClick={onGoNext}
        >
          {!goNextLoading && goNextLabel}
          {goNextLoading && (
            <>
              <div className="mr-2">
                <Spinner size={16} />
              </div>{' '}
              {goNextLabel}
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default PointsOfFooter;
