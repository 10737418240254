import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../App';
import { getApplication } from '../../store/exam/selectors';
import { useAppSelector } from '../../store/store';
import OTPContent from './OTPContent';
import OTPFooter from './OTPFooter';
import OTPHeader from './OTPHeader';

const OTP = () => {

  const { socket } = useSocketInstance();

  const navigator = useNavigate();

  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();

  const application = useAppSelector(getApplication);

  const [code, setCode] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const changeHandler = (v: string): void => setCode(v);

  const submitHandler = (): void => {
    setLoading(true);
    socket.emit('otp-verify', { otp: code });
  };

  // redirect to intro if user have already done otp verification
  useEffect(() => {
    if (application && Object.keys(application).length > 0 && application.otp_verified === 1) {
      navigator(`/${job_post_alias}/${application_alias}/intro`);
    }
  }, [application]);

  useEffect(() => {
    if (code && code.trim().length === 6) {
      !isValid && setIsValid(true);
    } else {
      isValid && setIsValid(false);
    }
  }, [code]);

  return (
    <div className={clsx(
      'h-full flex flex-col items-center justify-start',
      'lg:justify-center'
    )}>
      <div className={clsx(
        'flex h-full w-full max-w-[500px] items-center justify-center',
        'lg:mb-8 lg:h-auto'
      )}>
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-start bg-[#FFFFFF]',
            'lg:justify-center lg:h-auto lg:rounded-[8px] lg:p-6 lg:shadow-cust-1'
          )}
        >

          {/* Header */}
          <OTPHeader />

          {/* Content */}
          <OTPContent
            loading={loading}
            onChange={changeHandler} />

          {/* Footer */}
          <OTPFooter
            loading={loading}
            disabled={!isValid}
            onSubmit={submitHandler} />

        </div>
      </div>
      <div
        className={clsx(
          'hidden items-center justify-center',
          'lg:flex'
        )}>
        <img
          src="/algointerview-logo.svg"
          alt="" />
      </div>
    </div>
  );

};

export default OTP;
