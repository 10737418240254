import AlreadyDone from './AlreadyDone';
import Approved from './Approved';
import Rejected from './Rejected';

interface Props {
  alreadyDone: boolean;
  managerName: string;
  managerSurname: string;
  applicationName: string;
  applicationSurname: string;
  status: 'approve' | 'reject';
}

const ApprovalStatus = ({ alreadyDone, managerName, managerSurname, applicationName, applicationSurname, status }: Props) => {

  if (alreadyDone) {
    return <AlreadyDone
      managerName={managerName}
      managerSurname={managerSurname}
      applicationName={applicationName}
      applicationSurname={applicationSurname} />;
  }

  return status === 'approve'
    ? (
      <Approved
        managerName={managerName}
        managerSurname={managerSurname}
        applicationName={applicationName}
        applicationSurname={applicationSurname}
        status="approved" />
    )
    : (
      <Rejected
        managerName={managerName}
        managerSurname={managerSurname}
        applicationName={applicationName}
        applicationSurname={applicationSurname}
        status="rejected" />
    );

};

export default ApprovalStatus;
