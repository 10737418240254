import clsx from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import Button from '../../../shared/components/Button/Button';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import JobHeader from '../../../shared/components/JobHeader/JobHeader';
import LanguageSelector from '../../../shared/components/LanguageSelector/LanguageSelector';
import StakeholderNotAllowed from '../../../shared/components/StakeholderNotAllowed/StakeholderNotAllowed';
import { EMAIL_REGEX } from '../../../shared/utils/regex';
import { DISABLE_LOADING } from '../../../store/exam/reducer';
import { getAllowedDomains, getCompany } from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { GuestUiErrors, actions } from '../../../store/ui/reducer';
import { getGuestErrors, getIntroLoading } from '../../../store/ui/selectors';

interface FormState {
  name: { value: string; valid: boolean; touched: boolean };
  surname: { value: string; valid: boolean; touched: boolean };
  email: { value: string; valid: boolean; touched: boolean };
}

const Guest = () => {
  // Hooks
  const { socket } = useSocketInstance();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  // Selectors
  const loading = useAppSelector(getIntroLoading);
  const company = useAppSelector(getCompany);
  const allowed_domains = useAppSelector(getAllowedDomains);
  const errors = useAppSelector(getGuestErrors);
  // States
  const [state, setState] = useState<FormState>({
    name: { value: '', valid: false, touched: false },
    surname: { value: '', valid: false, touched: false },
    email: { value: '', valid: false, touched: false },
  });
  const [formErrors, setFormErrors] = useState<GuestUiErrors>({});
  const [checkbox, setCheckbox] = useState<boolean>(false);
  // Computed
  const referral_hash: string | null = searchParams.get('i');
  const notAllowed = errors['stakeholders_limit'];
  // Handlers
  const validateInput = (value: string, type: string): boolean => {
    let valid = false;
    let error = '';
    switch (type) {
      case 'name':
      case 'surname': {
        valid = value.trim().length >= 2;
        error = t('guest.input.name.error');
        break;
      }
      case 'email': {
        const syntax_is_valid: boolean = EMAIL_REGEX.test(value.trim());
        const domain_is_valid: boolean = allowed_domains.includes(
          value.trim().substring(value.indexOf('@') + 1)
        );
        valid = syntax_is_valid && domain_is_valid;
        error = t('guest.input.email.error');
        break;
      }
    }
    if (!valid) {
      setFormErrors((state) => ({
        ...state,
        [type as keyof GuestUiErrors]: error,
      }));
    } else {
      const newState = { ...formErrors };
      delete newState[type as keyof GuestUiErrors];
      setFormErrors(newState);
    }
    return valid;
  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    setState((prev: FormState) => ({
      ...prev,
      [e.target.name as keyof FormState]: {
        value: e.target.value,
        valid: validateInput(e.target.value, e.target.name),
        touched: true,
      },
    }));
  };

  const submitHandler = (): void => {
    dispatch(actions.GUEST_SUBMIT());
    socket.emit('guest-authenticate', {
      name: state.name.value.trim(),
      surname: state.surname.value.trim(),
      email: state.email.value.trim(),
      referral_hash,
    });
  };
  // Effects
  useEffect(() => {
    dispatch(actions.GUEST_ERROR_RESET_ALL());
    dispatch(DISABLE_LOADING());
  }, [dispatch]);

  useEffect(() => {
    if (formErrors.name) {
      dispatch(actions.GUEST_ERROR({ name: 'name', error: formErrors['name'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('name'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors.name]);

  useEffect(() => {
    if (formErrors.surname) {
      dispatch(actions.GUEST_ERROR({ name: 'surname', error: formErrors['surname'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('surname'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors.surname]);

  useEffect(() => {
    if (formErrors.email) {
      dispatch(actions.GUEST_ERROR({ name: 'email', error: formErrors['email'] }));
    } else {
      dispatch(actions.GUEST_ERROR_RESET('email'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors.email]);

  useEffect(() => {
    if (!referral_hash) {
      navigator('/');
    }
  }, [navigator, referral_hash]);

  return (
    <div className={clsx('flex h-full flex-col items-center justify-start', 'lg:justify-center')}>
      <div
        className={clsx(
          'flex h-full w-full max-w-[500px] items-center justify-center',
          'lg:mb-8 lg:h-auto'
        )}
      >
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-start bg-[#FFFFFF]',
            'lg:h-auto lg:justify-center lg:rounded-[8px] lg:p-6 lg:shadow-cust-1'
          )}
        >
          <div
            className={clsx(
              'flex w-full flex-row items-center justify-between border-b border-b-[#D0D5DD] p-4 shadow',
              'lg:border-none lg:p-0 lg:shadow-none'
            )}
          >
            <div className="flex w-full items-center justify-between">
              <JobHeader logoOnly={true} />
            </div>
            <LanguageSelector />
          </div>
          {/* Form elements */}
          {!notAllowed && (
            <>
              <div
                className={clsx(
                  'mt-6 flex w-full flex-col items-start justify-stretch px-4',
                  'lg:flex-row lg:px-0'
                )}
              >
                <HeadingText
                  className="font-semibold"
                  variant="primary-small"
                >
                  {t('guest.heading')}
                </HeadingText>
              </div>

              <div
                className={clsx(
                  'mt-6 flex w-full flex-col items-start justify-stretch px-4',
                  'lg:px-0'
                )}
              >
                <div className="mb-4 w-full">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-[15px]"
                  >
                    {t('guest.form.name.label')}
                  </label>
                  <input
                    required
                    id="name"
                    name="name"
                    className={clsx(
                      'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600',
                      'focus:border-[2px] focus:border-[#4344dc]',
                      'disabled:cursor-not-allowed disabled:opacity-50',
                      { 'border-[2px] border-[#df1b41]': errors['name'] }
                    )}
                    type="text"
                    placeholder={t('guest.form.name.placeholder')}
                    value={state.name.value}
                    onChange={changeHandler}
                  />
                  {errors['name'] && <div className="text-xs text-[#df1b41]">{errors['name']}</div>}
                </div>
                <div className="mb-4 w-full">
                  <label
                    htmlFor="surname"
                    className="mb-2 block text-[15px]"
                  >
                    {t('guest.form.surname.label')}
                  </label>
                  <input
                    required
                    id="surname"
                    name="surname"
                    className={clsx(
                      'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600',
                      'focus:border-[2px] focus:border-[#4344dc]',
                      'disabled:cursor-not-allowed disabled:opacity-50',
                      { 'border-[2px] border-[#df1b41]': errors['surname'] }
                    )}
                    type="text"
                    placeholder={t('guest.form.surname.placeholder')}
                    value={state.surname.value}
                    onChange={changeHandler}
                  />
                  {errors['surname'] && (
                    <div className="text-xs text-[#df1b41]">{errors['surname']}</div>
                  )}
                </div>
                <div className="mb-4 w-full">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-[15px]"
                  >
                    {t('guest.form.email.label')}
                  </label>
                  <input
                    required
                    id="email"
                    name="email"
                    className={clsx(
                      'text-md flex h-10 w-full rounded-md border bg-[#ffffff] px-3 py-2 placeholder:text-gray-600',
                      'focus:border-[2px] focus:border-[#4344dc]',
                      'disabled:cursor-not-allowed disabled:opacity-50',
                      { 'border-[2px] border-[#df1b41]': errors['email'] }
                    )}
                    type="email"
                    placeholder={t('guest.form.email.placeholder')}
                    value={state.email.value}
                    onChange={changeHandler}
                  />
                  {errors['email'] && (
                    <div className="text-xs text-[#df1b41]">{errors['email']}</div>
                  )}
                </div>
              </div>
              <div className="my-4 flex w-full flex-col justify-start px-4 lg:px-0">
                <div className={clsx('mb-10 flex w-full flex-col', 'lg:mb-0')}>
                  <Checkbox
                    key="introduction-checkbox-company_policy"
                    name="company_policy"
                    label={t('guest.privacy.company_policy', {
                      companyName: company.name,
                      companyPolicyUrl: company.privacy_policy_url,
                    })}
                    onChange={() => setCheckbox((state) => !state)}
                  />
                  <small className="mt-4">
                    <i>{t('guest.disclaimer')}</i>
                  </small>
                </div>
                <div className={clsx('flex items-center justify-center', 'lg:hidden')}>
                  <img
                    src="/skillvue-logo.svg"
                    alt=""
                  />
                </div>
              </div>
              <div
                className={clsx(
                  'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
                  'lg:static lg:justify-center lg:border-0 lg:pb-0'
                )}
              >
                <Button
                  loading={loading}
                  disabled={
                    !checkbox || !state.name.valid || !state.surname.valid || !state.email.valid
                  }
                  className="default flex-1"
                  onClick={submitHandler}
                >
                  {t('guest.button.start')}
                </Button>
              </div>
            </>
          )}
          {/* Error block */}
          {notAllowed && <StakeholderNotAllowed />}
        </div>
      </div>
      <div className={clsx('hidden items-center justify-center', 'lg:flex')}>
        <img
          src="/skillvue-logo.svg"
          alt="App logo"
        />
      </div>
    </div>
  );
};

export default Guest;
