import clsx from 'clsx';
import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocketInstance } from '../../../App';
import { Language } from '../../../interfaces';
import SaveDraftDialog from '../../../shared/components/Dialog/Contents/SaveDraftDialog';
import Dialog from '../../../shared/components/Dialog/Dialog';
import PointsOfFooter from '../../../shared/components/PointsOfFooter/PointsOfFooter';
import { ClusterQuestion, TaskCluster } from '../../../shared/socket/socket.interfaces';
import i18n from '../../../shared/utils/i18n';
import { SELECT_ANSWER } from '../../../store/exam/reducer';
import {
  getAvailableLanguages,
  getCompleteTask,
  getCurrentCluster,
  getIsLastCluster,
  getSelectedTask,
  getTaskFeedbacks,
} from '../../../store/exam/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getNextLoading, getPrevLoading } from '../../../store/ui/selectors';
import TaskContent from './TaskContent';
import TaskHeader from './TaskHeader';

const Task = () => {
  // Hooks
  const { socket } = useSocketInstance();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const { job_post_alias, application_alias } = useParams<{
    job_post_alias?: string;
    application_alias?: string;
  }>();
  // Selectors
  const languages = useAppSelector(getAvailableLanguages);
  const complete_task = useAppSelector(getCompleteTask);
  const current_cluster = useAppSelector(getCurrentCluster);
  const prevLoading = useAppSelector(getPrevLoading);
  const nextLoading = useAppSelector(getNextLoading);
  const isLastCluster = useAppSelector(getIsLastCluster);
  const selected_task = useAppSelector(getSelectedTask);
  const feedbacks = useAppSelector(getTaskFeedbacks);
  // State
  const [currentLanguage, setCurrentLanguage] = useState<Language | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // Computed
  const readonly = selected_task?.status === 'completed';

  // Handlers
  const scoreClickHandler = (question_id: number, vote: number): void => {
    dispatch(SELECT_ANSWER({ question_id, vote }));
  };

  const goBackToAssessmentList = (_: MouseEvent<HTMLButtonElement>): void => {
    socket.emit('go-back-to-assessment-list', {
      ...current_cluster,
    });
  };

  const goBackHandler = (_: MouseEvent<HTMLButtonElement>): void => {
    socket.emit('questionnaire-prev', {
      ...current_cluster,
    });
  };

  const goNextHandler = (_: MouseEvent<HTMLButtonElement>): void => {
    if (isLastCluster) {
      socket.emit('questionnaire-complete', {
        ...current_cluster,
      });
      return;
    }
    socket.emit('questionnaire-next', {
      ...current_cluster,
    });
  };

  const saveDraftHandler = (): void => {
    socket.emit('save-draft', {
      voted_id: current_cluster.voted_id,
      questions: current_cluster.questions.filter((q) => q.score !== null),
      feedbacks: [],
    });
  };
  // Effects
  // detect language change
  useEffect(() => {
    if (i18n && i18n.resolvedLanguage) {
      const current_language: Language = languages.find(
        (language: Pick<Language, 'id' | 'label' | 'iso_code'>) =>
          language.iso_code === i18n.resolvedLanguage
      ) as Language;
      setCurrentLanguage(current_language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (!complete_task || Object.keys(complete_task).length === 0) {
      navigator(`/${job_post_alias}/${application_alias}/intro`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete_task]);

  return (
    <div className={clsx('flex h-full flex-col items-center justify-start', 'lg:justify-center')}>
      <div className={clsx('flex h-full w-full items-center justify-center', 'lg:max-w-[90%]')}>
        <div
          className={clsx(
            'flex h-full w-full flex-col items-center justify-start bg-[#FFFFFF]',
            'lg:max-h-[886px] lg:rounded-[8px] lg:shadow-cust-1'
          )}
        >
          {/* Header */}
          <TaskHeader
            fullname={current_cluster.name}
            currentQuestion={
              complete_task.findIndex((t: TaskCluster) => t.cluster === current_cluster.cluster) + 1
            }
            totalQuestion={complete_task.length + feedbacks.length}
            cluster={t(`360.task.category.${current_cluster.cluster}`)}
          />

          {/* Content */}
          <TaskContent
            readonly={readonly}
            currentLanguage={currentLanguage}
            questions={current_cluster.questions}
            onScoreClick={scoreClickHandler}
          />

          {/* Footer */}
          <PointsOfFooter
            currentQuestion={
              complete_task.findIndex((t: TaskCluster) => t.cluster === current_cluster.cluster) + 1
            }
            totalQuestion={complete_task.length + feedbacks.length}
            readonly={readonly}
            canGoBack={!prevLoading}
            canGoNext={
              readonly ||
              (!readonly &&
                !nextLoading &&
                current_cluster?.questions?.every((t: ClusterQuestion) => t.score !== null))
            }
            goBackLabel={t('360.button.back')}
            goNextLabel={t('360.button.next')}
            goBackLoading={prevLoading}
            goNextLoading={nextLoading}
            onSaveDraft={() => setIsDialogOpen(true)}
            onGoBack={
              complete_task.findIndex((t: TaskCluster) => t.cluster === current_cluster.cluster) > 0
                ? goBackHandler
                : goBackToAssessmentList
            }
            onGoNext={goNextHandler}
          />
        </div>
      </div>
      <Dialog isOpen={isDialogOpen}>
        <SaveDraftDialog
          onClose={() => setIsDialogOpen(false)}
          onConfirm={() => saveDraftHandler()}
        />
      </Dialog>
    </div>
  );
};

export default Task;
