import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/components/Button/Button';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import { getApplication, getCompany, getPoliciesAccepted } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';
import { getIntroLoading } from '../../../store/ui/selectors';
import DescriptionText from './DescriptionText';
import IntroHeader from './IntroHeader';
import VideoPlayer from './VideoPlayer';

const { VITE_APP_INTRODUCTION_VIDEO_URL } = import.meta.env;
interface Props {
  onSubmit: () => void;
}

const IntroBox = ({ onSubmit }: Props) => {
  // Hooks
  const { t } = useTranslation();
  // Selectors
  const loading = useAppSelector(getIntroLoading);
  const company = useAppSelector(getCompany);
  const application = useAppSelector(getApplication);
  const policies_accepted = useAppSelector(getPoliciesAccepted);
  // State
  const [alreadyAccepted, setAlreadyAccepted] = useState<boolean>(policies_accepted);
  const [checkbox, setCheckbox] = useState<boolean>(false);
  // Effects
  useEffect(() => {
    if (policies_accepted && !alreadyAccepted) {
      setAlreadyAccepted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies_accepted]);

  return (
    <div className="m-[auto] flex w-full max-w-5xl items-center justify-center">
      <div
        className={clsx(
          'flex h-full w-full flex-col items-center justify-center bg-[#FFFFFF]',
          'lg:h-auto lg:rounded-lg lg:shadow-cust-1'
        )}
      >
        {/* Header */}
        <IntroHeader />
        {/* Content */}
        <div
          className={clsx(
            'my-6 flex w-full flex-col items-stretch justify-stretch px-6',
            'lg:flex-row'
          )}
        >
          <div className="lg:h-100 mr-8 flex flex-1 items-center justify-start lg:overflow-hidden">
            <DescriptionText
              name={application.name}
              surname={application.surname}
            />
          </div>
          {VITE_APP_INTRODUCTION_VIDEO_URL && (
            <div className="lg:h-100 flex-1">
              <VideoPlayer video={VITE_APP_INTRODUCTION_VIDEO_URL} />
            </div>
          )}
        </div>
        {/* Policies (conditional) */}
        {!alreadyAccepted && (
          <div className="w-full">
            {/* Disclaimer text */}
            <div className="pb-6">
              <div className="my-6 flex w-full flex-col justify-start px-6">
                {/* CheckBoxes */}
                <div className="mb-20 flex w-full flex-col lg:mb-0">
                  <Checkbox
                    key="introduction-checkbox-company_policy"
                    name="company_policy"
                    label={t('guest.privacy.company_policy', {
                      companyName: company.name,
                      companyPolicyUrl: company.privacy_policy_url,
                    })}
                    onChange={() => setCheckbox((state) => !state)}
                  />
                  <small className="mt-4">
                    <strong>
                      <i>{t('guest.disclaimer')}</i>
                    </strong>
                  </small>
                </div>
              </div>
              {/* Button */}
              <div
                className={clsx(
                  'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
                  'lg:static lg:justify-end lg:border-0 lg:px-6 lg:py-0'
                )}
              >
                <Button
                  loading={loading}
                  disabled={!checkbox}
                  className="default"
                  onClick={onSubmit}
                >
                  {t('guest.button.start')}
                </Button>
              </div>
            </div>
          </div>
        )}
        {/* Coming from guest sign in, button is enabled by default */}
        {alreadyAccepted && (
          <div
            className={clsx(
              'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
              'lg:static lg:justify-end lg:rounded-lg lg:border-0 lg:p-6'
            )}
          >
            <Button
              loading={loading}
              className="default"
              onClick={onSubmit}
            >
              {t('guest.button.start')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntroBox;
