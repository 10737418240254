import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CheckDoubleLineIcon from '../../shared/components/Icons/CheckDoubleLineIcon';

interface Props {
  managerName: string;
  managerSurname: string;
  applicationName: string;
  applicationSurname: string;
}

const AlreadyDone = ({ managerName, managerSurname, applicationName, applicationSurname }: Props) => {

  const { t } = useTranslation();

  return (
    <>
      <div className={clsx(
        'w-[48px] h-[48px] p-2 rounded-full flex items-center justify-center bg-[#1F883D] mb-4'
      )}>
        <CheckDoubleLineIcon color='#DAFBE1' />
      </div>
      <div dangerouslySetInnerHTML={{
        __html: t('360.approval.already-done', {
          manager_name: managerName,
          manager_surname: managerSurname,
          application_name: applicationName,
          application_surname: applicationSurname
        })
      }} />
    </>
  );

};

export default AlreadyDone;
