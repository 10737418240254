import { useTranslation } from 'react-i18next';
import HeadingText from '../../../shared/components/HeadingText/HeadingText';
import LanguageSelector from '../../../shared/components/LanguageSelector/LanguageSelector';
import { getCompany } from '../../../store/exam/selectors';
import { useAppSelector } from '../../../store/store';

const IntroHeader = () => {
  // Hooks
  const { t } = useTranslation();
  const company = useAppSelector(getCompany);

  return (
    <div className="flex w-full flex-row items-stretch justify-between border-b border-b-[#D0D5DD] px-5 py-2">
      {/* Left container */}
      <div className="flex flex-row">
        <div className="mr-4">
          <img
            src="/unicredit-logo-small.svg"
            alt="Unicredit official logo - small version"
          />
        </div>
        <div>
          <HeadingText className="font-bold">{t('360.assessments.header.title')}</HeadingText>
          <HeadingText variant="secondary">
            {t('360.assessments.header.subtitle', { companyName: company.name })}
          </HeadingText>
        </div>
      </div>
      {/* Right container */}
      <LanguageSelector />
    </div>
  );
};

export default IntroHeader;
