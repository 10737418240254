import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/components/Button/Button';

interface Props {
  loading: boolean;
  disabled: boolean;
  onSubmit: () => void;
}

const OTPFooter = ({ loading, disabled, onSubmit }: Props) => {

  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'fixed bottom-0 z-10 flex w-full justify-center border-t-[1px] border-solid border-[#D0D5DD] bg-[#FFFFFF] py-2',
        'lg:static lg:justify-center lg:border-0 lg:pb-0'
      )}
    >
      <Button
        loading={loading}
        disabled={disabled}
        className="default flex-1"
        onClick={onSubmit}
      >
        {t('otp.button.label')}
      </Button>
    </div>
  );

};

export default OTPFooter;
