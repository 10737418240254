import { createSelector } from '@reduxjs/toolkit';
import {
  Application,
  Company,
  Job_Post,
  Q360Task,
  Q360TaskList,
  Questionnaire,
  Soft_Skill,
  Soft_Skill_Question,
} from '../../interfaces';
import { TaskCluster, TaskFeedback } from '../../shared/socket/socket.interfaces';
import { RootState } from '../store';
import { ExamState } from './reducer';

const ExamSlice = (state: RootState) => state.exam;

export const getLoading = createSelector(ExamSlice, (state: ExamState): boolean => state.loading);

export const getAvailableLanguages = createSelector(
  ExamSlice,
  (state: ExamState) => state.available_languages
);

export const getAllowedDomains = createSelector(
  ExamSlice,
  (state: ExamState) => state.allowed_domains
);

export const getCompany = createSelector(ExamSlice, (state: ExamState): Company => state.company);

export const getJobPost = createSelector(ExamSlice, (state: ExamState): Job_Post => state.job_post);

export const getApplication = createSelector(
  ExamSlice,
  (state: ExamState): Application => state.application
);

export const getCompleteTask = createSelector(
  ExamSlice,
  (state: ExamState): TaskCluster[] => state.q360_selected_task
);

export const getSoftSkills = createSelector(
  ExamSlice,
  (state: ExamState): Soft_Skill[] => state.soft_skills
);

export const getAllQuestions = createSelector(
  ExamSlice,
  (state: ExamState): Soft_Skill_Question[] =>
    state.soft_skills.reduce(
      (acc: Soft_Skill_Question[], value: Soft_Skill) => [...acc, ...value.questions],
      []
    )
);

export const getQuestionnaires = createSelector(
  ExamSlice,
  (state: ExamState): Questionnaire[] => state.questionnaires
);

export const get360SelfTask = createSelector(
  ExamSlice,
  (state: ExamState): Q360Task =>
    state.q360_task_list.self && state.q360_task_list.self.length > 0
      ? state.q360_task_list.self[0]
      : ({} as Q360Task)
);

export const get360TaskList = createSelector(
  ExamSlice,
  (state: ExamState): Omit<Q360TaskList, 'self'> => ({
    'direct-report': state.q360_task_list['direct-report'],
    peer: state.q360_task_list.peer,
    manager: state.q360_task_list.manager,
    'other-stakeholder': state.q360_task_list['other-stakeholder'],
  })
);

export const getCurrentCluster = createSelector(
  ExamSlice,
  (state: ExamState): TaskCluster => state.next_step
);

export const getIsLastCluster = createSelector(
  [getCompleteTask, getCurrentCluster],
  (list: TaskCluster[], current: TaskCluster): boolean =>
    current.cluster === list[list.length - 1]?.cluster
);

export const getVotedId = createSelector(
  ExamSlice,
  (state: ExamState): number => state.next_step.voted_id
);

export const getCanShareLink = createSelector(
  ExamSlice,
  (state: ExamState): boolean => state.can_share
);

export const getTaskFeedbacks = createSelector(
  ExamSlice,
  (state: ExamState): TaskFeedback[] => state.feedbacks
);

export const getPointOfStrengthFeedback = createSelector(
  getTaskFeedbacks,
  (state: TaskFeedback[]): TaskFeedback | undefined =>
    state.find((f: TaskFeedback) => f.type === 'strength')
);

export const getPointOfImprovementFeedback = createSelector(
  getTaskFeedbacks,
  (state: TaskFeedback[]): TaskFeedback | undefined =>
    state.find((f: TaskFeedback) => f.type === 'improvement')
);

export const getSelectedTask = createSelector(
  ExamSlice,
  (state: ExamState): Q360Task | undefined => {
    const tasks: Q360Task[] =
      state.q360_task_list && Object.keys(state.q360_task_list).length > 0
        ? [
            ...state.q360_task_list.self,
            ...state.q360_task_list['direct-report'],
            ...state.q360_task_list.peer,
            ...state.q360_task_list.manager,
            ...state.q360_task_list['other-stakeholder'],
          ]
        : [];
    return tasks.find((t) => t.id === state.next_step.voted_id);
  }
);

export const getJobPostIntroductionContent = createSelector(
  getJobPost,
  (state: Job_Post): string => state.introduction_content
);

export const getJobPostWelcomeVideo = createSelector(
  getJobPost,
  (state: Job_Post): string | null => state.welcome_video
);

export const getPoliciesAccepted = createSelector(
  ExamSlice,
  (state: ExamState): boolean => state.policies_accepted
);

export const getManagerApprovalLoading = createSelector(
  ExamSlice,
  (state: ExamState): boolean => state.manager_approval.loading
);

export const getVotedName = createSelector(
  ExamSlice,
  (state: ExamState): string => state.manager_approval.voted.name
);

export const getVotedSurname = createSelector(
  ExamSlice,
  (state: ExamState): string => state.manager_approval.voted.surname
);

export const getManagerName = createSelector(
  ExamSlice,
  (state: ExamState): string => state.manager_approval.manager.name
);

export const getManagerSurname = createSelector(
  ExamSlice,
  (state: ExamState): string => state.manager_approval.manager.surname
);

export const getManagerAlreadyVoted = createSelector(
  ExamSlice,
  (state: ExamState): boolean => state.manager_approval.alreadyVoted
);

export const getHasError = createSelector(ExamSlice, (state) => state.hasError);
