
const EnglishFlagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={21}
      fill="none"
    >
      <rect width={28} height={20} y={0.5} fill="#fff" rx={2} />
      <mask
        id="a"
        width={28}
        height={21}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <rect width={28} height={20} y={0.5} fill="#fff" rx={2} />
      </mask>
      <g mask="url(#a)">
        <path fill="#0A17A7" d="M0 .5h28v20H0z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m-1.282-1.417 11.949 8.06V-.833h6.667v7.476l11.949-8.06 1.49 2.211-9.447 6.373H28v6.666h-6.674l9.448 6.373-1.491 2.21-11.95-8.06v7.477h-6.666v-7.476l-11.95 8.06-1.49-2.211 9.447-6.373H0V7.167h6.674L-2.773.794l1.49-2.21Z"
          clipRule="evenodd"
        />
        <path
          stroke="#DB1F35"
          strokeLinecap="round"
          strokeWidth={0.667}
          d="M18.668 6.832 31.333-1.5M20.013 14.197l11.353 7.653M8.005 6.81-3.838-1.17M9.29 14.105l-13.128 8.706"
        />
        <path
          fill="#E6273E"
          fillRule="evenodd"
          d="M0 12.5h12v8h4v-8h12v-4H16v-8h-4v8H0v4Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default EnglishFlagIcon;
