import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import HeadingText from '../../shared/components/HeadingText/HeadingText';
import SixDigitCode from '../../shared/components/SixDigitCode/SixDigitCode';
import { getApplication } from '../../store/exam/selectors';
import { useAppSelector } from '../../store/store';

interface Props {
  loading: boolean;
  onChange: (v: string) => void;
}

const OTPContent = ({ loading, onChange }: Props) => {

  const { t } = useTranslation();

  const application = useAppSelector(getApplication);

  return (
    <div
      className={clsx(
        "w-full p-5"
      )}>
      <div
        className={clsx(
          "w-full text-left"
        )}>
        <HeadingText
          variant="primary"
          className="font-bold">{t('otp.title')}</HeadingText>
        <HeadingText
          variant="primary-small"
          className="mt-6">
          {t('otp.text', { email: application.email })}
        </HeadingText>
      </div>
      <div
        className="">
        <SixDigitCode
          loading={loading}
          onChange={onChange} />
      </div>
    </div>
  );

};

export default OTPContent;
