import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../Icons/CheckIcon';
import CircleCaretRightIcon from '../Icons/CircleCaretRightIcon';
import MemoPadIcon from '../Icons/MemoPadIcon';

interface Props {
  status: 'completed' | 'in-progress' | 'not-started';
}

const AssessmentStatusBadge = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-md px-2 py-1 text-sm font-semibold',
        {
          'text-muted bg-white': status === 'not-started',
          'bg-white-opaque text-muted': status === 'in-progress',
          'bg-accent-dark text-white': status === 'completed',
        }
      )}
    >
      {status === 'not-started' && (
        <>
          <CircleCaretRightIcon className="mr-1" /> {t('360.assessments.task-not-started')}
        </>
      )}
      {status === 'in-progress' && (
        <>
          <MemoPadIcon className="mr-1" /> {t('360.assessments.task-in-progress')}
        </>
      )}
      {status === 'completed' && (
        <>
          <CheckIcon className="mr-1" /> {t('360.assessments.task-completed')}
        </>
      )}
    </div>
  );
};

export default AssessmentStatusBadge;
