import { SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
  bgcolor?: string;
}

const TriangleIconWithBg = ({ ...props }: Props) => {
  return (
    <svg
      width={props.width ?? 40}
      height={props.height ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={props.width ?? 40}
        height={props.height ?? 40}
        rx={20}
        fill={props.bgcolor ?? '#000'}
      />
      <path
        d="M19.703 13.043a.344.344 0 01.297-.168c.121 0 .234.063.297.168l7.746 12.723a.567.567 0 01-.484.86H12.44a.567.567 0 01-.484-.86l7.746-12.723zm-1.601-.977L10.355 24.79a2.442 2.442 0 002.086 3.71H27.56a2.442 2.442 0 002.085-3.71l-7.746-12.723a2.224 2.224 0 00-3.796 0zm3.148 12.059a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm-.313-7.188A.935.935 0 0020 16a.935.935 0 00-.938.938v3.75c0 .519.418.937.938.937s.938-.418.938-.938v-3.75z"
        fill={props.color ?? '#fff'}
      />
    </svg>
  );
};

export default TriangleIconWithBg;
