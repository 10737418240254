import { useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import PageLoader from './shared/components/PageLoader/PageLoader';
import useSocket from './shared/socket/useSocket';
import { getLoading } from './store/exam/selectors';
import { useAppSelector } from './store/store';

type ContextType = { socket: Socket; connected: boolean; };

export const useSocketInstance = () => useOutletContext<ContextType>();

const App = () => {
  const { socket, connected } = useSocket();

  const loading = useAppSelector(getLoading);

  // Prevent the use of the browser's back and forward buttons after starting the test
  useEffect(() => {
    const handler = (_: PopStateEvent): void => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.history.pushState(null, document.title, window.location.href);

    window.addEventListener('popstate', handler);

    return () => {
      window.removeEventListener('popstate', handler);
    };
  }, []);

  return (
    <div>
      {loading && <PageLoader size={64} />}
      <Outlet context={{ socket, connected }} />
    </div>
  );
};

export default App;
